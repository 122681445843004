import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';
import Slider from 'rc-slider'; // 슬라이더 라이브러리
import 'rc-slider/assets/index.css'; // 슬라이더 스타일
import Canvas2 from '../Components/Canvas2';
import Graph2 from '../Components/Graph2';
import '../Styles/compare.css'
import { useToken } from '../Components/TokenContext';
import AWS from 'aws-sdk';
import { showAlert } from '../Components/alert';
import { useSocket } from "../Components/SocketContext"
import { isMobile, isTablet } from 'react-device-detect';

const CoachCompare = () => {
  const {socket} = useSocket();
  const navigate = useNavigate();
  const location = useLocation();
  
  const Generalvideopath = location.state?.videopath; // 마이페이지에서 가져옴
  const Generalindex = location.state?.generalindex; // 마이페이지에서 가져옴
  const Generalpage = sessionStorage.getItem('generalpage') // 마이페이지 페이지
  const Generalcursor = sessionStorage.getItem('generalcursor') ? JSON.parse(sessionStorage.getItem('generalcursor')) : []; // 마이페이지 커서

  const [generalindex, setGeneralindex] = useState(Generalindex);
  const [generalvideopath, setGeneralvideopath] = useState(Generalvideopath);
  const [generalpage, setGeneralPage] = useState(Generalpage);
  const [generalcursor, setGeneralcursor] = useState(Generalcursor);
 
                          // 마이페이지 일반영상 비교분석
//----------------------------------------------------------------------------------------
                          // 마이페이지 북마크영상 비교분석

  const Mybookmarkvideopath = location.state?.videopath; // 마이페이지에서 가져옴
  const Mybookmarkindex = location.state?.bookmarkindex; // 마이페이지에서 가져옴
  const Bookmarkpage = sessionStorage.getItem('bookmarkpage') // 마이페이지 페이지
  const Bookmarkcursor = sessionStorage.getItem('bookmarkcursor') ? JSON.parse(sessionStorage.getItem('bookmarkcursor')) : []; // 마이페이지 커서

  const [mybookmarkindex, setMybookmarkindex] = useState(Mybookmarkindex);
  const [mybookmarkvideopath, setMybookmarkvideopath] = useState(Mybookmarkvideopath);
  const [mybookmarkpage, setMybookmarkPage] = useState(Bookmarkpage);
  const [mybookmarkcursor, setMybookmarkcursor] = useState(Bookmarkcursor);
 
                          // 마이페이지 북마크 영상 비교분석
//----------------------------------------------------------------------------------------
                          // 회원관리 일반영상 비교분석

  const Studentvideopath = location.state?.videopath;
  const Studentindex = location.state?.studentindex;
  const Studentpage = sessionStorage.getItem('studentpage')
  const Studentcursor = sessionStorage.getItem('studentcursor') ? JSON.parse(sessionStorage.getItem('studentcursor')) : [];
  const Studentid = sessionStorage.getItem('studentId') // 학생 고유 id
  
  const [mystudentindex, setMystudentindex] = useState(Studentindex);
  const [mystudentvideopath, setMystudentvideopath] = useState(Studentvideopath);
  const [mystudentpage, setMystudentPage] = useState(Studentpage);
  const [mystudentcursor, setMystudentcursor] = useState(Studentcursor);
  const [mystudentId, setMystudentid] = useState(Studentid);

                            // 회원관리 일반영상 비교분석
//----------------------------------------------------------------------------------------


  const [visibleTables, setVisibleTables] = useState('visual');
  const [selectedGraph, setSelectedGraph] = useState([]);
  const [currentFrame1, setCurrentFrame1] = useState(0);
  const [currentFrame2, setCurrentFrame2] = useState(0);
  const [coordinates1, setCoordinates1] = useState([]);
  const [coordinates2, setCoordinates2] = useState([]);
  const [c, setC] = useState([]); // 첫번 째 영상 7step
  const [d, setD] = useState([]); // 두번 째 영상 7step
  const [e, setE] = useState([]); // 영상1 썸네일 경로
  const [f, setF] = useState([]); // 선택한 학생 좌측 썸네일 경로
  const [g, setG] = useState([]); // 선택한 학생 우측 썸네일 경로

  const [all1, setAll1] = useState([]); // 전체 데이터
  const [all2, setAll2] = useState([]); // 전체 데이터
  const [frameRate1, setFrameRate1] = useState();
  const [frameRate2, setFrameRate2] = useState();
  const [videolength1, setVideolength1] = useState();
  const [videolength2, setVideolength2] = useState();
  const [jsonlength1, setJsonlength1] = useState();
  const [jsonlength2, setJsonlength2] = useState();
  const { studentId, coachId, setJsonAll1, setJsonAll2 } = useToken();
  const [selectedVideoPath1, setSelectedVideoPath1] = useState('');
  const [selectedVideoPath2, setSelectedVideoPath2] = useState('');
  const [videopath1, setVideoPath1] = useState([]);
  const [videopath2, setVideoPath2] = useState([]);
  const [thumbnailPath1, setThumbnailPath1] = useState([]); // 좌측 썸네일 전체의 경로 저장
  const [thumbnailPath2, setThumbnailPath2] = useState([]); // 우측 썸네일 전체의 경로 저장
  const [videoUrl1, setVideoUrl1] = useState(null);
  const [videoUrl2, setVideoUrl2] = useState(null);
  const [index1, setIndex1] = useState(null);
  const [index2, setIndex2] = useState(null);
  const [activeStep1, setActiveStep1] = useState(null);
  const [activeStep2, setActiveStep2] = useState(null);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedstep, setSelectedStep] = useState('');
  const [borderselectedstep, setBorderselectedStep] = useState('');


  const [thumbnails1, setThumbnailUrls1] = useState([]);
  const [thumbnails2, setThumbnailUrls2] = useState([]);
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [videoSize1, setVideoSize1] = useState({width: '0px', height: '0px'});
  const [videoSize2, setVideoSize2] = useState({width: '0px', height: '0px'});
  const [graphSize, setGraphSize] = useState({width: 0, height: 0});


  const [mystudent, setMystudent] = useState([]); // 내 회원(대기 중 포함)
  const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
  const [selectedStudentId1, setSelectedStudentId1] = useState('self');
  const [selectedStudentId2, setSelectedStudentId2] = useState('self');

  const [bookmarkindex1, setBookmarkIndex1] = useState(null); // 모달 좌측 북마크 영상
  const [bookmarkindex2, setBookmarkIndex2] = useState(null); // 모달 우측 북마크 영상
  const [bookmarkvideopath1, setBookmarkVideoPath1] = useState([]);
  const [bookmarkvideopath2, setBookmarkVideoPath2] = useState([]);
  const [bookmarkthumbnailPath1, setBookmarkthumbnailPath1] = useState([]);
  const [bookmarkthumbnailPath2, setBookmarkthumbnailPath2] = useState([]); 
  const [bookmarkthumbnails1, setBookmarkthumbnailUrls1] = useState([]);
  const [bookmarkthumbnails2, setBookmarkthumbnailUrls2] = useState([]);
  const [socketflag, setSocketflag] = useState(0);
  const [uploadFlag, setUploadFlag] = useState(false);

  const [cursor1, setCursor1] = useState([0]); // 모달 커서
  const [total1, setTotal1] = useState(-1); // 총 영상 개수
  const [videoId1, setVideoId1] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [jsonarr1, setJsonarr1] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [currentPage1, setCurrentPage1] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [cursor2, setCursor2] = useState([0]); // 모달 커서
  const [total2, setTotal2] = useState(-1); // 총 영상 개수
  const [videoId2, setVideoId2] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [jsonarr2, setJsonarr2] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [currentPage2, setCurrentPage2] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [bookmarkcursor1, setBookmarkcursor1] = useState([0]); // 모달 커서
  const [bookmarktotal1, setBookmarktotal1] = useState(-1); // 총 영상 개수
  const [bookvideoId1, setBookvideoId1] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [bookjsonarr1, setBookjsonarr1] = useState([]); // // 북마크영상 id 파싱을 위한 take만큼의 json 배열
  const [bookcurrentPage1, setBookcurrentPage1] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [bookmarkcursor2, setBookmarkcursor2] = useState([0]); // 모달 커서
  const [bookmarktotal2, setBookmarktotal2] = useState(-1); // 총 영상 개수
  const [bookvideoId2, setBookvideoId2] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [bookjsonarr2, setBookjsonarr2] = useState([]); // // 북마크영상 id 파싱을 위한 take만큼의 json 배열
  const [bookcurrentPage2, setBookcurrentPage2] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [studentcursor1, setStudentcursor1] = useState([0]); // 모달 커서
  const [studenttotal1, setStudenttotal1] = useState(-1); // 총 영상 개수
  const [studentvideoId1, setStudentvideoId1] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [studentjsonarr1, setStudentjsonarr1] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [studentcurrentPage1, setStudentCurrentPage1] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [studentcursor2, setStudentcursor2] = useState([0]); // 모달 커서
  const [studenttotal2, setStudenttotal2] = useState(-1); // 총 영상 개수
  const [studentvideoId2, setStudentvideoId2] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [studentjsonarr2, setStudentjsonarr2] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [studentcurrentPage2, setStudentCurrentPage2] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  var itemsPerPage = 6;
  if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
    itemsPerPage = 4;
  }

  Modal.setAppElement('#root')
  const { jsonall1, jsonall2 } = useToken();
  const updateVisibleTables = (value) => {
    setVisibleTables(value);
};

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
        // 태블릿 가로 모드일 때의 크기 설정
        setVideoSize1({ width: '270px', height: '420px' });
        setVideoSize2({ width: '270px', height: '420px' });
        setGraphSize({width: 500, height: 300})
      } else {
        // 기타 모드일 때의 크기 설정
        setVideoSize1({ width: '540px', height: '720px' });
        setVideoSize2({ width: '540px', height: '720px' });
        setGraphSize({width: 800, height: 500})
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  var i;

  let joint = ["left_wrist", "right_wrist", "left_hip", "right_hip","left_shoulder", "right_shoulder", "left_knee", "right_knee",
  "left_heel", "right_heel", "left_toe", "right_toe","left_eye", "right_eye", "left_elbow", "right_elbow", "ears"];
  let joint2=[]

  joint.forEach(idx => {
    joint2.push(idx+'.x');
    joint2.push(idx+'.y');
  })

  joint = joint2

  const take = isTablet ? 4 : 6;
  const token2 = 'Bearer ' + sessionStorage.getItem('token')
  const apiId = sessionStorage.getItem('apiId');
  const apitype = sessionStorage.getItem('apitype');

if(socket){
  socket.on("KM:upload", data => {
    console.log("KM:upload")
    setUploadFlag(true)
    setSocketflag(socketflag+1);
  })
}

useEffect(() => {
  if(Generalindex !== undefined && Mybookmarkindex === undefined && index1 === null && bookmarkindex1 === null){

    setSelectedVideoPath1(Generalvideopath);
    if (videoId1 !== null) {
      setVideoId1(null);
    }
    if (bookvideoId1 !== null) {
      setBookvideoId1(null);
    }
    if (index1 !== null) {
      setIndex1(null);
    }
    if (bookmarkindex1 !== null) {
      setBookmarkIndex1(null);
    }
  }
  if(Mybookmarkindex !== undefined && Generalindex === undefined && index1 === null && bookmarkindex1 === null){

    setSelectedVideoPath1(Mybookmarkvideopath);
    if (videoId1 !== null) {
      setVideoId1(null);
    }
    if (bookvideoId1 !== null) {
      setBookvideoId1(null);
    }
    if (index1 !== null) {
      setIndex1(null);
    }
    if (bookmarkindex1 !== null) {
      setBookmarkIndex1(null);
    }
  }
  if(Studentindex !== undefined && Mybookmarkindex === undefined && Generalindex === undefined && index1 === null && bookmarkindex1 === null){

    setSelectedVideoPath1(Mybookmarkvideopath);
    if (videoId1 !== null) {
      setVideoId1(null);
    }
    if (bookvideoId1 !== null) {
      setBookvideoId1(null);
    }
    if (index1 !== null) {
      setIndex1(null);
    }
    if (bookmarkindex1 !== null) {
      setBookmarkIndex1(null);
    }
  }
}, [Generalindex, Mybookmarkindex, Generalvideopath, Mybookmarkvideopath, index1, bookmarkindex1,, selectedVideoPath1, videoId1, bookvideoId1, bookmarkindex1, Studentindex]);

useEffect(() => { // 일반영상 좌측 dataJSON 받아오기

  if(selectedStudentId1 === 'self'){
  const fetchData = async() => {
    let url;
    if(generalindex !== undefined && index1 === null && mybookmarkindex === undefined && bookmarkindex1 === null){
      url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('generalId')}/${apitype}/${apiId}`;
    }
    else{
      url = `http://117.16.243.158:9000/myswing/${videoId1}/${apitype}/${apiId}`;
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();
      const myjson = JSON.parse(json.data.swingInfo.dataJSON);
      var data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));
   
      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
    
      setCoordinates1(data);
      setVideolength1(myjson.data.video_length);
      setJsonlength1(myjson.data.json_length);
      setFrameRate1(adjustframeRate)
      setAll1(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll1(myjson);
      setC(myjson.data.golf_step);
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
    const fetchStudentData = async() => { // 좌측 회원 일반영상 dataJSON
      let url;

      if(mystudentindex !== undefined){
        url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('studentvideoId')}/${apitype}/${apiId}`;
      }
      else{
        url = `http://117.16.243.158:9000/myswing/${studentvideoId1}/${apitype}/${apiId}`;
      }
      try {
      const response = await fetch(url,{
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
        if(response.status === 401)
        {
          //showAlert('로그인 후 이용해 주세요');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
          //navigate('/login');
        }
        const json = await response.json();
        const myjson = JSON.parse(json.data.swingInfo.dataJSON);
        const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
      setCoordinates1(data);
      setVideolength1(myjson.data.video_length);
      setJsonlength1(myjson.data.json_length);
      setFrameRate1(adjustframeRate)
      setAll1(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll1(myjson);
      setC(myjson.data.golf_step);
      const transformedData = transformSwingsData(json);
      setMetadata(transformedData);

      }catch(error){
        console.log('Error', error);
        setE([]);
      }
    };
    fetchStudentData();
  }
}, [selectedStudentId1, index1, videoId1, currentPage1, generalpage, bookmarkindex1]);
 // generalindex, videoId1, currentPage1, generalpage, bookmarkindex1, mybookmarkindex 수정 전

useEffect(() => { // 좌측 일반영상 json 받아오기

  if(selectedStudentId1 === 'self'){
    const fetchData = async() => { // json가져오기
      let url;

      if (generalindex !== undefined && index1 === null && mybookmarkindex === undefined && bookmarkindex1 === null) {
        url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}?take=${take}&cursor=${cursor1[currentPage1 - 1]}`; // generalcursor[generalpage - 1]
      } else {
        url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}?take=${take}&cursor=${cursor1[currentPage1 - 1]}`;
      }
      try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();
      setJsonarr1(json);
      const videopath = json.data.myswings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.myswings.map((swing) => ({ path: swing.thumbnail }));


      if(index2 !== null || bookmarkindex2 !== null){
        if(index1 === null && bookmarkindex1 === null){
          setVideoId1(json.data.myswings[generalindex !== undefined ? generalindex : index1].id)
        }else{
          //setVideoId1(json.data.myswings[index1].id);
          setVideoId1(json.data.myswings[index1 === null ? 0 : index1].id);
        }
      }else if(index1 !== null){
        setVideoId1(json.data.myswings[index1 === null ? 0 : index1].id);
      }

      //setVideoId1(json.data.myswings[(index1 === null && bookmarkindex1 === null) ? 0 : index1].id);
  
      //setE(thumbnailpath);
      setVideoPath1(videopath)
      setThumbnailPath1(thumbnailpath)

      cursor1[currentPage1] = json.data.cursor
      setCursor1([...cursor1]);
  
      if(total1 === -1){
        setTotal1(json.data.total);
      }
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
  const fetchStudentData = async() => { // 좌측 회원 일반영상 json 가져오기

    let url;

    if (mystudentindex !== undefined) {
      url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}/student?studentId=${mystudentId}&take=${take}&cursor=${studentcursor1[studentcurrentPage1-1]}`; // mystudentcursor[mystudentpage - 1]
    } else {
      url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}/student?studentId=${selectedStudentId1}&take=${take}&cursor=${studentcursor1[studentcurrentPage1-1]}`;
    }
    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
      if(response.status === 401)
      {
        //showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        //navigate('/login');
      }
      const json = await response.json();
      setStudentjsonarr1(json);

      const videopath = json.data.studentSwings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.studentSwings.map((swing) => ({ path: swing.thumbnail }));

      if(index1 !== null){
        setStudentvideoId1(json.data.studentSwings[index1 === null ? 0 : index1].id)
      }
    //setStudentvideoId1(json.data.studentSwings[(index1 === null && bookmarkindex1 === null) ? 0 : index1].id);
    setVideoPath1(videopath)
    setThumbnailPath1(thumbnailpath)
    setF(thumbnailpath);

    const transformedData = transformSwingsData(json);
    setMetadata(transformedData);

    studentcursor1[studentcurrentPage1] = json.data.cursor
    setStudentcursor1([...studentcursor1]);

    if(studenttotal1 === -1){
      setStudenttotal1(json.data.total);
    }

    }catch(error){
      console.log('Error', error);
      setE([]);
    }
  };
  fetchStudentData();
}

}, [selectedStudentId1, index1, socketflag, currentPage1, generalpage, generalindex, studentcurrentPage1]);
// selectedStudentId1, selectedVideoPath1, index1, socketflag, currentPage1, generalpage, generalindex, studentcurrentPage1 수정 전
useEffect(() => { // 일반영상 우측 dataJSON 받아오기

  if(selectedStudentId2 === 'self'){
  const fetchData = async() => {

    let url;
    url = `http://117.16.243.158:9000/myswing/${videoId2}/${apitype}/${apiId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();
      const myjson = JSON.parse(json.data.swingInfo.dataJSON);
      var data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));
   
      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
    
      setCoordinates2(data);
      setVideolength2(myjson.data.video_length);
      setJsonlength2(myjson.data.json_length);
      setFrameRate2(adjustframeRate)
      setAll2(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll2(myjson);
      setD(myjson.data.golf_step);
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
    const fetchStudentData = async() => { // 우측 회원 일반영상 dataJSON

      let url;
      url = `http://117.16.243.158:9000/myswing/${studentvideoId2}/${apitype}/${apiId}`;

      try {
      const response = await fetch(url,{
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
        if(response.status === 401)
        {
          //showAlert('로그인 후 이용해 주세요');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
          //navigate('/login');
        }
        const json = await response.json();
        const myjson = JSON.parse(json.data.swingInfo.dataJSON);
        const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
      setCoordinates2(data);
      setVideolength2(myjson.data.video_length);
      setJsonlength2(myjson.data.json_length);
      setFrameRate2(adjustframeRate)
      setAll2(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll2(myjson);
      setD(myjson.data.golf_step);
      const transformedData = transformSwingsData(json);
      setMetadata(transformedData);

      }catch(error){
        console.log('Error', error);
        setE([]);
      }
    };
    fetchStudentData();
  }
}, [index2, videoId2, currentPage2, generalpage, bookmarkindex2, selectedStudentId2]);

useEffect(() => { // 우측 일반영상 json 받아오기

  if(selectedStudentId2 === 'self'){
    const fetchData = async() => { // json가져오기
      let url;
      url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}?take=${take}&cursor=${cursor2[currentPage2 - 1]}`;

      try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();

      setJsonarr2(json);
      const videopath = json.data.myswings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.myswings.map((swing) => ({ path: swing.thumbnail }));

      // if ((generalindex === undefined && index2 === null && bookmarkindex2 === null) || uploadFlag === true) {
      //   setSelectedVideoPath2(videopath[0].path);
      //   setVideoId2(json.data.myswings[0].id);
      // }else if(index2 === null){
      //   setVideoId2(null);
      // }
      // else if(index2 !== null){
      //   setVideoId2(json.data.myswings[index2 === null ? 0 : index2].id);
      // }

      if (((generalindex === undefined && index2 === null && bookmarkindex2 === null)) || uploadFlag === true) {
        setSelectedVideoPath2(videopath[0].path);
        setVideoId2(json.data.myswings[0].id);
      }else if(index2 === null){
        setVideoId2(null);
        if(generalindex !== undefined){
          setSelectedVideoPath2(videopath[0].path);
          setVideoId2(json.data.myswings[0].id);
        }
      }
      else if(index2 !== null){
        setVideoId2(json.data.myswings[index2 === null ? 0 : index2].id);
      }
  
      setVideoPath2(videopath)
      setThumbnailPath2(thumbnailpath)

      cursor2[currentPage2] = json.data.cursor
      setCursor2([...cursor2]);
  
      if(total2 === -1){
        setTotal2(json.data.total);
      }
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
  const fetchStudentData = async() => { // 우측 회원 일반영상 json 가져오기

    let url;
    url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}/student?studentId=${selectedStudentId2}&take=${take}&cursor=${studentcursor2[studentcurrentPage2-1]}`;

    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
      if(response.status === 401)
      
      {
        //showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        //navigate('/login');
      }
      const json = await response.json();
      setStudentjsonarr2(json);

      const videopath = json.data.studentSwings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.studentSwings.map((swing) => ({ path: swing.thumbnail }));

      // if ((generalindex === undefined && mybookmarkindex === undefined && index2 === null && bookmarkindex2 === null) || uploadFlag === true) {
      //   setSelectedVideoPath2(videopath[0].path);
      //   setVideoId2(json.data.myswings[0].id);
      // }else{
      //   setVideoId2(json.data.myswings[index2 === null ? 0 : index2].id);
      // }

      // if ((generalindex === undefined && mybookmarkindex === undefined && index2 === null && bookmarkindex2 === null) || uploadFlag === true) {
      //   setSelectedVideoPath2(videopath[0].path);
      //   setStudentvideoId2(json.data.studentSwings[0].id);
      // }
      // else if(index2 === null){
      //   setStudentvideoId2(null)
      // }
      // else if(index2 !== null){
      //   setStudentvideoId2(json.data.studentSwings[index2 === null ? 0 : index2].id)
      // }

      if (((generalindex === undefined && index2 === null && bookmarkindex2 === null)) || uploadFlag === true) {
        setSelectedVideoPath2(videopath[0].path);
        setStudentvideoId2(json.data.studentSwings[0].id);
      }else if(index2 === null){
        setStudentvideoId2(null)
        if(generalindex !== undefined){
          setSelectedVideoPath2(videopath[0].path);
          setStudentvideoId2(json.data.studentSwings[0].id);
        }
      }
      else if(index2 !== null){
        setStudentvideoId2(json.data.studentSwings[index2 === null ? 0 : index2].id)
      }


    setVideoPath2(videopath)
    setThumbnailPath2(thumbnailpath)
    setF(thumbnailpath);

    const transformedData = transformSwingsData(json);
    setMetadata(transformedData);

    studentcursor2[studentcurrentPage2] = json.data.cursor
    setStudentcursor2([...studentcursor2]);

    if(studenttotal2 === -1){
      setStudenttotal2(json.data.total);
    }

    }catch(error){
      console.log('Error', error);
      setE([]);
    }
  };
  fetchStudentData();
}

}, [index2, socketflag, currentPage2, generalpage, generalindex, studentcurrentPage2, selectedStudentId2]);

useEffect(() => {
  if ((videopath2.length > 0 && selectedVideoPath2 === '') || uploadFlag === true) {
    setSelectedVideoPath2(videopath2[0].path);
  }
}, [videopath2, selectedVideoPath2, socketflag]);

useEffect(() => { // 북마크영상 좌측 dataJSON 받기

  const fetchBookmarkData = async() => {
    let url;
    if(mybookmarkindex !== undefined && bookmarkindex1 === null && generalindex === undefined && index1 === null){
      url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('bookmarkId')}/${apitype}/${apiId}`;
    }
    else if(bookmarkindex1 !== null && videoId1 === null && bookvideoId1 !== null){
      url = `http://117.16.243.158:9000/myswing/${bookvideoId1}/${apitype}/${apiId}`;
    }
    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      //navigate('/login');
    }

    const json = await response.json();
    const myjson = JSON.parse(json.data.swingInfo.dataJSON);
    const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

    let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;

    //setBookmarkAll(myjson);
    setCoordinates1(data);
    setVideolength1(myjson.data.video_length);
    setJsonlength1(myjson.data.json_length);
    setFrameRate1(adjustframeRate)
    setAll1(myjson.data.frames.map((frame)=> frame.point));
    setJsonAll1(myjson);
    setC(myjson.data.golf_step);
    }catch(error){
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
},[bookmarkindex1, bookvideoId1 ,bookcurrentPage1, mybookmarkpage, index1]);

useEffect(() => { // 북마크영상 좌측 json 받기

  const fetchBookmarkData = async() => {
    let url;

    if(mybookmarkindex !== undefined && index1 === null && generalindex === undefined && bookmarkindex1 === null){
      url = `http://117.16.243.158:9000/bookmark/${apitype}/${apiId}?take=${take}&cursor=${bookmarkcursor1[bookcurrentPage1 - 1]}`; // mybookmarkcursor[mybookmarkpage - 1]
    } else{
      url = `http://117.16.243.158:9000/bookmark/${apitype}/${apiId}?take=${take}&cursor=${bookmarkcursor1[bookcurrentPage1 - 1]}`;
    }

    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      //navigate('/login');
    }

    const json = await response.json();
    setBookjsonarr1(json)
    const thumbnailpath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.thumbnail }));
    const videopath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.videoPath }));

    //setBookvideoId1(json.data.bookmarks[bookmarkindex1 === null && index1 === null ? 0 : bookmarkindex1].myswing.id)
    setBookmarkthumbnailPath1(thumbnailpath)
    setBookmarkVideoPath1(videopath);
    
    bookmarkcursor1[bookcurrentPage1] = json.data.cursor
    setBookmarkcursor1([...bookmarkcursor1]);

    if(bookmarktotal1 === -1){
      setBookmarktotal1(json.data.total);
    }

    }catch(error){
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
},[bookmarkindex1, bookcurrentPage1, mybookmarkpage, bookvideoId1]);
// bookmarkindex1, bookcurrentPage1, mybookmarkpage, mybookmarkindex, bookvideoId1 수정 전

useEffect(() => { // 북마크영상 우측 dataJSON 받기

  const fetchBookmarkData = async() => {
    let url;
    url = `http://117.16.243.158:9000/myswing/${bookvideoId2}/${apitype}/${apiId}`;

    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      //navigate('/login');
    }

    const json = await response.json();
    const myjson = JSON.parse(json.data.swingInfo.dataJSON);
    const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

    let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;

    //setBookmarkAll(myjson);
    setCoordinates2(data);
    setVideolength2(myjson.data.video_length);
    setJsonlength2(myjson.data.json_length);
    setFrameRate2(adjustframeRate)
    setAll2(myjson.data.frames.map((frame)=> frame.point));
    setJsonAll2(myjson);
    setD(myjson.data.golf_step);

    }catch(error){
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
},[bookmarkindex2, bookvideoId2 ,bookcurrentPage2, mybookmarkpage, index2]);


useEffect(() => { // 북마크영상 우측 json 받기

  const fetchBookmarkData = async() => {
    let url;
    url = `http://117.16.243.158:9000/bookmark/${apitype}/${apiId}?take=${take}&cursor=${bookmarkcursor2[bookcurrentPage2 - 1]}`;

    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      //navigate('/login');
    }

    const json = await response.json();
    setBookjsonarr2(json)
    const thumbnailpath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.thumbnail }));
    const videopath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.videoPath }));


    if(bookmarkindex2 === null){
      setBookvideoId2(null)
    }else{
      setBookvideoId2(json.data.bookmarks[bookmarkindex2].myswing.id)
    }
    setBookmarkthumbnailPath2(thumbnailpath)
    setBookmarkVideoPath2(videopath);

    bookmarkcursor2[bookcurrentPage2] = json.data.cursor
    setBookmarkcursor2([...bookmarkcursor2]);

    if(bookmarktotal2 === -1){
      setBookmarktotal2(json.data.total);
    }

    }catch(error){
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
},[bookmarkindex2, bookcurrentPage2, mybookmarkpage, mybookmarkindex]);

useEffect(() => {
  const fetchStudents = async() => { // 등록된 학생정보 가져오기 Approve로 파싱
    try {
    const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      //sessionStorage.removeItem('nickname')
    }
    
    const data = await response.json();
      if (data.data && data.data.lessons) {

        const studentData = data.data.lessons.map((user) => ({
            lessonId: user.id,
            studentid: user.student.id,
            name: user.student.user.name,
            loginId: user.student.user.loginId,
            isApprove: user.isApprove
        }));
        setMystudent(studentData);
  }
    }catch(error){
      console.log('Error', error);
    }
  };
  fetchStudents();

}, [coachId]);


useEffect(() => { // 좌측 영상 다운로드

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
    region: 'ap-northeast-2' // e.g., 'us-west-2'
  });
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';
  const objectKey = selectedVideoPath1;

  const params = {
    Bucket: bucketName,
    Key: objectKey
  };

s3.getObject(params, (error, data) => {
    if (error) {
      console.error("Error downloading video from S3:", error);
    } else {
      // Assuming the data is a Blob
      const blob = new Blob([data.Body], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setVideoUrl1(videoUrl);
    }
  });

},[selectedVideoPath1, socketflag, cursor1, studentcursor1]);

useEffect(() => { // 우측 영상 다운로드

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
    region: 'ap-northeast-2' // e.g., 'us-west-2'
  });
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';
  const objectKey = selectedVideoPath2;

  const params = {
    Bucket: bucketName,
    Key: objectKey
  };

s3.getObject(params, (error, data) => {
    if (error) {
      console.error("Error downloading video from S3:", error);
    } else {
      // Assuming the data is a Blob
      const blob = new Blob([data.Body], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setVideoUrl2(videoUrl);
    }
  });

},[selectedVideoPath2, socketflag, cursor2, studentcursor2]);
  

useEffect(() => { // 좌측 일반영상 썸네일 다운
  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
    region: 'ap-northeast-2' // e.g., 'us-west-2'
  });

  // Initialize S3 client
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata'; // Your S3 bucket name

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = thumbnailPath1.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path, // Assuming `item.path` has the correct key for the thumbnail
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setThumbnailUrls1(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (thumbnailPath1.length > 0) {
    fetchThumbnails();
  }
}, [thumbnailPath1, socketflag, cursor1, studentcursor1]);

useEffect(() => { // 우측 일반영상 썸네일 다운
  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
    region: 'ap-northeast-2' // e.g., 'us-west-2'
  });

  // Initialize S3 client
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata'; // Your S3 bucket name

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = thumbnailPath2.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path, // Assuming `item.path` has the correct key for the thumbnail
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setThumbnailUrls2(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (thumbnailPath2.length > 0) {
    fetchThumbnails();
  }
}, [thumbnailPath2, socketflag, cursor2, studentcursor2]);

useEffect(() => { // 좌측 북마크 영상 썸네일 다운

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2'
  });

  // Initialize S3 client
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = bookmarkthumbnailPath1.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setBookmarkthumbnailUrls1(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (bookmarkthumbnailPath1.length > 0) {
    fetchThumbnails();
  }
}, [bookmarkthumbnailPath1, bookmarkcursor1]);

useEffect(() => { // 우측 북마크 영상 썸네일 다운

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2'
  });

  // Initialize S3 client
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = bookmarkthumbnailPath2.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setBookmarkthumbnailUrls2(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (bookmarkthumbnailPath2.length > 0) {
    fetchThumbnails();
  }
}, [bookmarkthumbnailPath2, bookmarkcursor2]);

  const [playbackRate, setPlaybackRate] = useState(1.0); // 재생 속도 상태 추가
  const [playing1, setPlaying1] = useState(false); // 영상 재생 여부 상태 추가
  const [playing2, setPlaying2] = useState(false); // 영상 재생 여부 상태 추가
  const playerRef1 = useRef(null); // ReactPlayer 참조를 위한 ref
  const playerRef2 = useRef(null); // ReactPlayer 참조를 위한 ref
  const [repeat, setRepeat] = useState(false);

  // 슬라이더 값 상태 추가
  const [sliderValue1, setSliderValue1] = useState();
  const [sliderValue2, setSliderValue2] = useState();
  const [sliderValue3, setSliderValue3] = useState();

  // 슬라이더 값 변경 핸들러


const handleSliderChange1 = (value) => {
    setSliderValue1(value);
    setCurrentFrame1(value);
  };

  const handleSliderChange2 = (value) => {
    setSliderValue2(value);
    setCurrentFrame2(value);
  };

  const handleSliderChange3 = (value) => {
    setSliderValue3(value);
    if (playerRef1.current && playerRef1.current.getInternalPlayer() && playerRef2.current && playerRef2.current.getInternalPlayer()) {

      const totalDurationSeconds1 = videolength1 / frameRate1;
      const targetTime1 = (totalDurationSeconds1 * (value / videolength1)) / (videolength1/frameRate1);
      const totalDurationSeconds2 = videolength2 / frameRate2;
      const targetTime2 = (totalDurationSeconds2 * (value / videolength2)) / (videolength2/frameRate2);
 
      playerRef1.current.getInternalPlayer().pause();
      playerRef2.current.getInternalPlayer().pause();
      if (!isNaN(targetTime1) && isFinite(targetTime1)) {
        playerRef1.current.seekTo(targetTime1);
        playerRef2.current.seekTo(targetTime2);
      }
    }
  };

  // 슬라이더 변경이 끝난 후에 영상 제어
  const handleSliderChangeEnd1 = (sliderValue) => {
    if (playerRef1.current) {
      let targetTime = sliderValue / frameRate1;
      const roundedTime = Number((Math.round(targetTime * 100) / 100).toFixed(2)); // 소수점 두 자리로 반올림

      // 41의 배수 프레임일 경우 예외 처리
      // if ((sliderValue) % 41 === 0) {
      //   targetTime = Number((Math.round(targetTime / 0.05) * 0.05).toFixed(2));
      //   console.log(targetTime)
      // }

      if (!isNaN(targetTime) && isFinite(targetTime)) {
        const maxTime = playerRef1.current.getDuration(); // 영상의 총 시간
        let validTime // 원하는 재생시간
        if(sliderValue % 41 !== 0){
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2));
        }
        else{
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2)) + 0.001;
        }

        // console.log(`Slider Value: ${sliderValue}`);
        // console.log(`Target Time: ${targetTime}`);
        // console.log(`Rounded Time: ${roundedTime}`);
        // console.log(`Valid Time: ${validTime}`);
        playerRef1.current.seekTo(validTime, 'second');
        playerRef1.current.getInternalPlayer().pause(); // 영상 재생 멈춤
      }
    }
  };

  const handleSliderChangeEnd2 = (sliderValue) => {
    if (playerRef2.current) {
      let targetTime = sliderValue / frameRate2;
      const roundedTime = Number((Math.round(targetTime * 100) / 100).toFixed(2)); // 소수점 두 자리로 반올림


      if (!isNaN(targetTime) && isFinite(targetTime)) {
        const maxTime = playerRef2.current.getDuration(); // 영상의 총 시간
        let validTime // 원하는 재생시간
        if(sliderValue % 41 !== 0){
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2));
        }
        else{
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2)) + 0.001;
        }

        // console.log(`Slider Value: ${sliderValue}`);
        // console.log(`Target Time: ${targetTime}`);
        // console.log(`Rounded Time: ${roundedTime}`);
        // console.log(`Valid Time: ${validTime}`);
        playerRef2.current.seekTo(validTime, 'second');
        playerRef2.current.getInternalPlayer().pause(); // 영상 재생 멈춤
      }
    }
  };

const handleSliderChangeEnd3 = (sliderValue) => {
    if (playerRef1.current && playerRef2.current) {
      const targetTime1 = sliderValue / frameRate1;
      const targetTime2 = sliderValue / frameRate2;
      
      if (!isNaN(targetTime1) && isFinite(targetTime1)) {
        const maxTime1 = playerRef1.current.getDuration(); // 영상의 총 시간
        let validTime1;
        const maxTime2 = playerRef2.current.getDuration(); // 영상의 총 시간
        let validTime2;
        if(sliderValue % 41 !== 0){
          validTime1 = Number(Math.min(Math.max(targetTime1, 0), maxTime1).toFixed(2));
          validTime2 = Number(Math.min(Math.max(targetTime2, 0), maxTime2).toFixed(2));
        }
        else{
          validTime1 = Number(Math.min(Math.max(targetTime1, 0), maxTime1).toFixed(2)) + 0.001;
          validTime2 = Number(Math.min(Math.max(targetTime2, 0), maxTime2).toFixed(2)) + 0.001;
        }
        playerRef1.current.seekTo(validTime1, 'second');
        playerRef1.current.getInternalPlayer().pause(); // 영상 재생 멈춤
        playerRef2.current.seekTo(validTime2, 'second');
        playerRef2.current.getInternalPlayer().pause(); // 영상 재생 멈춤
      }
    }
  };

  const handleVideoEnd1 = () => {
    setPlaying1(false); // 재생 상태를 false로 설정
    setSliderValue1(videolength1 - 1); // 슬라이더 값을 영상의 마지막 프레임으로 설정
  };

  const handleVideoEnd2 = () => {
    setPlaying2(false); // 재생 상태를 false로 설정
    setSliderValue2(videolength2 - 1); // 슬라이더 값을 영상의 마지막 프레임으로 설정
  };

  // 재생 속도 변경 핸들러
  const handleChangePlaybackRate = (rate) => {
    setPlaybackRate(rate);
    if (playerRef1.current && playerRef1.current.getInternalPlayer()) {
      playerRef1.current.getInternalPlayer().playbackRate = rate;
    }

    if (playerRef2.current && playerRef2.current.getInternalPlayer()) {
        playerRef2.current.getInternalPlayer().playbackRate = rate;
      }
  };

  const pause = () => {
    setPlaying1(!playing1);
    setPlaying2(!playing2);
  }

  const steps = ['address', 'take_away', 'half', 'top', 'down_half', 'impact', 'follow_through', 'finish'];

  const animationFrameId1 = useRef(null);  // 애니메이션 ID 저장
  const currentStepRef1 = useRef(null);  // 현재 반복 중인 스텝 저장
  const animationFrameId2 = useRef(null);  // 애니메이션 ID 저장
  const currentStepRef2 = useRef(null);  // 현재 반복 중인 스텝 저장

  const handleStepButtonClick1 = (step) => {
    setBorderselectedStep(step);
    if(step === "AD") step = 'address'
    else if(step === "TA") step = 'take_away'
    else if(step === "HF") step = 'half'
    else if(step === "TP") step = 'top'
    else if(step === "DH") step = 'down_half'
    else if(step === "IP") step = 'impact'
    else if(step === "FT") step = 'follow_through'
    else if(step === "FN") step = 'finish'

    // 🔥 기존 애니메이션 취소 (이전 루프 제거)
    if (animationFrameId1.current) {
      cancelAnimationFrame(animationFrameId1.current);
    }
    setSelectedStep(step);
    currentStepRef1.current = step;  // 현재 스텝 저장

    if (step === 'address') {
      if (playerRef1.current) {
        playerRef1.current.seekTo(0, 'seconds');
        setPlaying1(false);
        setSliderValue1(0);
      }
    }

    if (!jsonall1.data.golf_step[step]) return;

    const currentIndex = steps.indexOf(step);
    const downHalfExists = 'down_half' in jsonall1.data.golf_step;
    const startIndex = (() => {
      if (!downHalfExists && step === 'impact') {
          return currentIndex === 1 ? 0 : currentIndex - 2;
      }
      return currentIndex === 0 ? 0 : currentIndex - 1;
    })();
    const startFrame = step === 'address' ? 0 : c[steps[startIndex]];

    let targetFrame1;
    if (step === 'finish') {
      if (playerRef1.current) {
        const duration = playerRef1.current.getDuration();
        targetFrame1 = (Math.floor(duration * frameRate1)) - 1;
      } else {
        return;
      }
    } else {
      targetFrame1 = c[step];
    }

    const targetTime1 = targetFrame1 / frameRate1;

    const moveToFrame = () => {

      if (playerRef1.current && currentStepRef1.current === step) {
        const currentTime = playerRef1.current.getCurrentTime();
        if (currentTime <= targetTime1) {
          animationFrameId1.current = requestAnimationFrame(moveToFrame);
        } else {
          setPlaying1(false);
          setSliderValue1(targetFrame1);
          if (repeat && currentStepRef1.current === step) {
            // 🎯 steprepeat이 true면 무한 반복
            setTimeout(() => {
              if (currentStepRef1.current === step && playerRef1.current) {  // 🔥 선택한 스텝이 변경되지 않았는지 확인
                playerRef1.current.seekTo(startFrame / frameRate1, 'seconds'); // 다시 시작 프레임으로 이동
                setPlaying1(true);
                animationFrameId1.current = requestAnimationFrame(moveToFrame);
              }
            }, 500); // 0.5초 대기 후 다시 반복 시작
          }
        }
      }
    };

    if (playerRef1.current) {
      playerRef1.current.seekTo(startFrame / frameRate1, 'seconds');
      setPlaying1(true);
      animationFrameId1.current = requestAnimationFrame(moveToFrame);
    }
    setActiveStep1(step);
  };

  const handleStepButtonClick2 = (step) => {

    if(step === "AD") step = 'address'
    else if(step === "TA") step = 'take_away'
    else if(step === "HF") step = 'half'
    else if(step === "TP") step = 'top'
    else if(step === "DH") step = 'down_half'
    else if(step === "IP") step = 'impact'
    else if(step === "FT") step = 'follow_through'
    else if(step === "FN") step = 'finish'

    // 🔥 기존 애니메이션 취소 (이전 루프 제거)
    if (animationFrameId2.current) {
      cancelAnimationFrame(animationFrameId2.current);
    }
    currentStepRef2.current = step;  // 현재 스텝 저장

    if (step === 'address') {
      if (playerRef2.current) {
        playerRef2.current.seekTo(0, 'seconds');
        setPlaying2(false);
        setSliderValue2(0);
      }
    }

    if (!jsonall2.data.golf_step[step]) return;

    const currentIndex = steps.indexOf(step);
    const downHalfExists = 'down_half' in jsonall2.data.golf_step;
    const startIndex = (() => {
      if (!downHalfExists && step === 'impact') {
          return currentIndex === 1 ? 0 : currentIndex - 2;
      }
      return currentIndex === 0 ? 0 : currentIndex - 1;
    })();
    const startFrame = step === 'address' ? 0 : d[steps[startIndex]];

    let targetFrame2;
    if (step === 'finish') {
      if (playerRef2.current) {
        const duration = playerRef2.current.getDuration();
        targetFrame2 = (Math.floor(duration * frameRate2)) - 1;
      } else {
        return;
      }
    } else {
      targetFrame2 = d[step];
    }
    
    const targetTime2 = targetFrame2 / frameRate2;

    const moveToFrame = () => {
      if (playerRef2.current && currentStepRef2.current === step) {
        const currentTime = playerRef2.current.getCurrentTime();
        if (currentTime <= targetTime2) {
          animationFrameId2.current = requestAnimationFrame(moveToFrame);
        } else {
          setPlaying2(false);
          setSliderValue2(targetFrame2);
          if (repeat && currentStepRef2.current === step) {
            // 🎯 steprepeat이 true면 무한 반복
            setTimeout(() => {
              if (currentStepRef2.current === step && playerRef2.current) {  // 🔥 선택한 스텝이 변경되지 않았는지 확인
                playerRef2.current.seekTo(startFrame / frameRate2, 'seconds'); // 다시 시작 프레임으로 이동
                setPlaying2(true);
                animationFrameId2.current = requestAnimationFrame(moveToFrame);
              }
            }, 500); // 0.5초 대기 후 다시 반복 시작
          }
        }
      }
    };

    if (playerRef2.current) {
      playerRef2.current.seekTo(startFrame / frameRate2, 'seconds');
      setPlaying2(true);
      animationFrameId2.current = requestAnimationFrame(moveToFrame);
    }
    setActiveStep2(step);
  };

  const handleGraphSelection = (graphName) => {
    if (selectedGraph.includes(graphName)) {
      setSelectedGraph(selectedGraph.filter(item => item !== graphName));
    } else {
      setSelectedGraph([...selectedGraph, graphName]);
    }
  };

  // const handleVideoSelect1 = (event) => {
  //   const selectedPath1 = event.target.value;
  //   const index1 = videopath1.findIndex((video) => video.path === selectedPath1);
  //   setIndex1(index1);
  //   setSelectedVideoPath1(selectedPath1);
  // };

  // const handleVideoSelect2 = (event) => {
  //   const selectedPath2 = event.target.value;
  //   const index2 = videopath1.findIndex((video) => video.path === selectedPath2);
  //   setIndex2(index2);
  //   setSelectedVideoPath2(selectedPath2);
  // };

  const handleThumbnailClick1 = (thumbnailUrl1) => {
    const videoPath = thumbnailUrl1.replace(".jpg", ".mp4");
    const index = videopath1.findIndex((video) => video.path === videoPath);
    
    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');

    setIndex1(index);
    setVideoUrl1(videoPath);
    setSelectedVideoPath1(videoPath);
    setBookmarkIndex1(null);
    setBookvideoId1(null);
    if(selectedStudentId1 !== 'self'){
      setStudentvideoId1(studentjsonarr1.data.studentSwings[index].id)
    }
  };

  const handleThumbnailClick2 = (thumbnailUrl2) => {
    const videoPath = thumbnailUrl2.replace(".jpg", ".mp4");
    const index = videopath2.findIndex((video) => video.path === videoPath);

    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');

    setIndex2(index);
    setVideoUrl2(videoPath)
    setSelectedVideoPath2(videoPath);
    setBookmarkIndex2(null);
    setBookvideoId2(null);
    if(selectedStudentId2 !== 'self'){
      setStudentvideoId2(studentjsonarr2.data.studentSwings[index].id)
    }
  };

  const handleBookmarkThumbnailClick1 = (thumbnailUrl1) => {
    const videoPath = thumbnailUrl1.replace(".jpg", ".mp4");
    const index = bookmarkvideopath1.findIndex((video) => video.path === videoPath);

    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');
  
    setBookmarkIndex1(index);
    setVideoUrl1(videoPath);
    setSelectedVideoPath1(videoPath);

    setIndex1(null);
    setVideoId1(null);
    setBookvideoId1(bookjsonarr1.data.bookmarks[index].myswing.id)
  };

  const handleBookmarkThumbnailClick2 = (thumbnailUrl2) => {
    const videoPath = thumbnailUrl2.replace(".jpg", ".mp4");
    const index = bookmarkvideopath2.findIndex((video) => video.path === videoPath);

    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');
  
    setBookmarkIndex2(index);
    setVideoUrl2(videoPath);
    setSelectedVideoPath2(videoPath);

    setIndex2(null);
    setVideoId2(null);
    setBookvideoId2(bookjsonarr2.data.bookmarks[index].myswing.id)
  };

  const thumbnailname = (path) => {
    if (!path) {
      return '';
    }
    const parts = path.split('/');
    const filename = parts[parts.length - 1];
    const dateTimePart = filename.split('.')[0];
    return dateTimePart;
  };

  const flipCoordinates = (originalCoordinates, videoWidth) => {
    return originalCoordinates.map(point => {
      return {
        x: videoWidth - point.x,
        y: point.y 
      };
    });
  };
  
  const flippedCoordinates1 = flipCoordinates(coordinates1, 540);
  const flippedCoordinates2 = flipCoordinates(coordinates2, 540);

  const transformSwingsData = (generaldata) => {
    const transformedData = {};
    generaldata.data.studentSwings.forEach((swing) => {
      
      transformedData[swing.id] = {
        clubType: swing.clubType,
        createdAt: swing.createdAt,
        isOpen: swing.isOpen,
        note: swing.note,
      };
    });
    return transformedData;
  };

  const handleAccountChange1 = (e) => {
    setSelectedStudentId1(e.target.value);
    setBookmarkIndex1(null);
    setIndex1(null);
    setVideoId1(null);
  };
  const handleAccountChange2 = (e) => {
    setSelectedStudentId2(e.target.value);
    setBookmarkIndex2(null);
    setIndex2(null);
    setVideoId2(null);
  };
  
  // -------------------------------------------------
  // 좌측 영상
  const handlePrev1 = () => {
    if(currentPage1 > 1){ setCurrentPage1(currentPage1 - 1); // 0 미만으로 가지 않도록
  }
  };
  
  const handleNext1 = () => {
    if(currentPage1 < total1/take){
      setCurrentPage1(currentPage1 + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const handlebookmarkPrev1 = () => {
    if(bookcurrentPage1 > 1){ setBookcurrentPage1(bookcurrentPage1 - 1); // 0 미만으로 가지 않도록
  }
  };
  
  const handlebookmarkNext1 = () => {
    if(bookcurrentPage1 < bookmarktotal1/take){
      setBookcurrentPage1(bookcurrentPage1 + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  // 좌측 영상
  // -------------------------------------------------
  // 우측 영상

  const handlePrev2 = () => {
    if(currentPage2 > 1){ setCurrentPage2(currentPage2 - 1); // 0 미만으로 가지 않도록
  }
  };
  
  const handleNext2 = () => {
    if(currentPage2 < total2/take){
      setCurrentPage2(currentPage2 + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const handlebookmarkPrev2 = () => {
    if(bookcurrentPage2 > 1){ setBookcurrentPage2(bookcurrentPage2 - 1); // 0 미만으로 가지 않도록
  }
  };
  
  const handlebookmarkNext2 = () => {
    if(bookcurrentPage2 < bookmarktotal2/take){
      setBookcurrentPage2(bookcurrentPage2 + 1); // 최대 페이지를 초과하지 않도록
    }
  };
  // 우측 영상
  // -------------------------------------------------
  // 학생 영상 
  const handlestudentPrev1 = () => {
    if(studentcurrentPage1 > 1){ setStudentCurrentPage1(studentcurrentPage1 - 1); // 0 미만으로 가지 않도록
  }
  };

  const handlestudentNext1 = () => {
    if(studentcurrentPage1 < studenttotal1/take){
      setStudentCurrentPage1(studentcurrentPage1 + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const handlestudentPrev2 = () => {
    if(studentcurrentPage2 > 1){ setStudentCurrentPage2(studentcurrentPage2 - 1); // 0 미만으로 가지 않도록
  }
  };

  const handlestudentNext2 = () => {
    if(studentcurrentPage2 < studenttotal2/take){
      setStudentCurrentPage2(studentcurrentPage2 + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const stepTooltips = {
    AD: 'Address',
    TA: 'Take Away',
    HF: 'Half',
    TP: 'Top',
    DH: 'Down Half',
    IP: 'Impact',
    FT: 'Follow Through',
    FN: 'Finish'
  };
  
  const step = ['AD', 'TA', 'HF', 'TP', 'DH', 'IP', 'FT', 'FN']

  return (
    <>
      <div className="video-name1">
        {selectedVideoPath1}
      </div>
      <div className="video-name2">
        {selectedVideoPath2}
      </div>
      
      <div className='CApp'>

      {/* <div className='Cvideo-select1'>
        <label htmlFor='video-dropdown1'>Select a Video1:</label>
          <select id='video-dropdown1' value={selectedVideoPath1} onChange={handleVideoSelect1}>
            {videopath1.map((video, index) => (
              <option key={index} value={video.path}>
                {video.path}
              </option>
            ))}
          </select>
      </div>
      <div className='Cvideo-select2'>
        <label htmlFor='video-dropdown2'>Select a Video2:</label>
          <select id='video-dropdown2' value={selectedVideoPath2} onChange={handleVideoSelect2}>
            {videopath2.map((video, index) => (
              <option key={index} value={video.path}>
                {video.path}
              </option>
            ))}
          </select>
      </div> */}
      <div className='Cvideo-container'>
        <div className='Cvideo-player1'>
            <ReactPlayer
                ref={playerRef1}
                className='react-player1'
                url={videoUrl1}
                width={videoSize1.width}
                height={videoSize1.height}
                playing={playing1}
                muted={false}
                controls={false}
                light={false}
                pip={true}
                // loop={repeat === true ? true : false}
                loop={false}
                poster={videoUrl1}
                style={{ transform: isFlipped1 ? 'scaleX(-1)' : 'none' }}
                onEnded={handleVideoEnd1}
            />
      </div>
      <div className='Cvideo-player2'>
            <ReactPlayer
                ref={playerRef2}
                className='react-player2'
                url={videoUrl2}
                width={videoSize2.width}
                height={videoSize2.height}
                playing={playing2}
                muted={false}
                controls={false}
                light={false}
                pip={true}
                // loop={repeat === true ? true : false}
                loop={false}
                poster={videoUrl2}
                style={{ transform: isFlipped2 ? 'scaleX(-1)' : 'none' }}
                onEnded={handleVideoEnd2}
            />
        </div>
        <div className='Csteps-buttons'>
        {step.map((step) => (
          <button
            key={step}
            className='Cstep-button'
            onClick={() => {handleStepButtonClick1(step); handleStepButtonClick2(step) }}
            title={stepTooltips[step]}
            style={{
              border: borderselectedstep === step ? '5px solid red' : '2px solid white', // 선택된 단계에 따라 테두리 스타일 변경
            }}
          >
            {step.toUpperCase()}
          </button>
        ))}
          </div>
          <div className='Cflip1-buttons'>
        <button onClick={() => setIsFlipped1(!isFlipped1)}>좌우반전</button>
      </div>
      <div className='Cflip2-buttons'>
        <button onClick={() => setIsFlipped2(!isFlipped2)}>좌우반전</button>
      </div>

      <div className = 'Cgraph-container'>
        <div className = 'Cgraph-checkbox'>
{/*-------------------------------------wrist--------------------------------------------*/}
      {visibleTables === 'visual' && (
        <div className='Cbackangle-button2'>
          <label className="switch" style={{ color: 'white', marginTop : '1.5rem' }}>
            <input
              type="checkbox"
              checked={selectedGraph.includes('right_wrist')}
              onChange={() => handleGraphSelection('right_wrist')}
            />
            <span className="slider"></span> {/* 슬라이더 추가 */}
        </label>
        <span style = {{marginTop : '1rem', marginLeft : '0.5rem'}} className="switch-label">손목 경로</span>
        </div>
      )}
    </div>
      <div className='coachCchoose1-buttons'>
        <button onClick={() => setIsModalOpen1(true)}>영상1선택</button>
        <Modal
                isOpen={isModalOpen1}
                onRequestClose={() => setIsModalOpen1(false)}
                style={{
                  overlay: {
                    zIndex: 10,
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '80%'
                    }
                }}
            >
              <select style={{fontSize : '2rem'}} value={selectedStudentId1} onChange={handleAccountChange1}>
                <option value="self">내 영상</option>
                {mystudent.filter(student => student.isApprove).map(student => (
                  <option key={student.studentid} value={student.studentid}>{student.name}</option>
                ))}
              </select>

              <button onClick={() => setIsModalOpen1(false)}>닫기</button>
              {selectedStudentId1 === 'self' ?  
                  <label> 일반 영상 {total1}개</label>
                  : <label> 일반 영상 {studenttotal1}개</label>
                }
                {selectedStudentId1 === 'self' ?
                  <div className="thumbnail-container">
                    {thumbnailPath1 && thumbnailPath1.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick1(thumbnailPath1[index].path); setIsModalOpen1(false);}}
                          src={thumbnails1[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(thumbnailPath1[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <br></br>
                      <span onClick={handlePrev1} style={{ cursor: 'pointer', color: currentPage1 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {currentPage1} / {Math.ceil(total1 / itemsPerPage)}</span>
                      <span onClick={handleNext1} style={{ cursor: 'pointer', color: currentPage1 >= Math.ceil(total1 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  :
                  <div className="thumbnail-container">
                    {f && f.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick1(f[index].path); setIsModalOpen1(false);}}
                          src={thumbnails1[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(f[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <span onClick={handlestudentPrev1} style={{ cursor: 'pointer', color: studentcurrentPage1 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {studentcurrentPage1} / {Math.ceil(studenttotal1 / itemsPerPage)}</span>
                      <span onClick={handlestudentNext1} style={{ cursor: 'pointer', color: studentcurrentPage1 >= Math.ceil(studenttotal1 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  }
                  <label> 북마크 영상 {bookmarktotal1}개</label>
                  <div className="thumbnail-container">
                    {bookmarkthumbnailPath1 && bookmarkthumbnailPath1.map((item, bookmarkindex) => (
                      <div className="thumbnail-item" key={bookmarkindex}>
                        <img 
                          onClick={() => {handleBookmarkThumbnailClick1(bookmarkthumbnailPath1[bookmarkindex].path); setIsModalOpen1(false);}}
                          src={bookmarkthumbnails1[bookmarkindex]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(bookmarkthumbnailPath1[bookmarkindex].path)}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pagination-controls">
                  <span onClick={handlebookmarkPrev1} style={{ cursor: 'pointer', color: bookcurrentPage1 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                  <span> {bookcurrentPage1} / {Math.ceil(bookmarktotal1 / itemsPerPage)}</span>
                  <span onClick={handlebookmarkNext1} style={{ cursor: 'pointer', color: bookcurrentPage1 >= Math.ceil(bookmarktotal1 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                </div>
          </Modal>
      </div>

      <div className='coachCchoose2-buttons'>
        <button onClick={() => setIsModalOpen2(true)}>영상2선택</button>
        <Modal
                isOpen={isModalOpen2}
                onRequestClose={() => setIsModalOpen2(false)}
                style={{
                  overlay: {
                    zIndex: 10,
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '80%'
                    }
                }}
            >
              <select style={{fontSize : '2rem'}} value={selectedStudentId2} onChange={handleAccountChange2}>
                <option value="self">내 영상</option>
                {mystudent.filter(student => student.isApprove).map(student => (
                  <option key={student.studentid} value={student.studentid}>{student.name}</option>
                  ))}
              </select>

              <button onClick={() => setIsModalOpen2(false)}>닫기</button>
              {selectedStudentId2 === 'self' ?  
                  <label> 일반 영상 {total2}개</label>
                  : <label> 일반 영상 {studenttotal2}개</label>
                }
                {selectedStudentId2 === 'self' ?
                  <div className="thumbnail-container">
                    {thumbnailPath2 && thumbnailPath2.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick2(thumbnailPath2[index].path); setIsModalOpen2(false);}}
                          src={thumbnails2[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(thumbnailPath2[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <span onClick={handlePrev2} style={{ cursor: 'pointer', color: currentPage2 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {currentPage2} / {Math.ceil(total2 / itemsPerPage)}</span>
                      <span onClick={handleNext2} style={{ cursor: 'pointer', color: currentPage2 >= Math.ceil(total2 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  :
                  <div className="thumbnail-container">
                    {f && f.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick2(f[index].path); setIsModalOpen2(false);}}
                          src={thumbnails2[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(f[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <span onClick={handlestudentPrev2} style={{ cursor: 'pointer', color: studentcurrentPage2 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {studentcurrentPage2} / {Math.ceil(studenttotal2 / itemsPerPage)}</span>
                      <span onClick={handlestudentNext2} style={{ cursor: 'pointer', color: studentcurrentPage2 >= Math.ceil(studenttotal2 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  }
                  <label> 북마크 영상 {bookmarktotal2}개</label>
                  <div className="thumbnail-container">
                    {bookmarkthumbnailPath2 && bookmarkthumbnailPath2.map((item, bookmarkindex) => (
                      <div className="thumbnail-item" key={bookmarkindex}>
                        <img 
                          onClick={() => {handleBookmarkThumbnailClick2(bookmarkthumbnailPath2[bookmarkindex].path); setIsModalOpen2(false);}}
                          src={bookmarkthumbnails2[bookmarkindex]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(bookmarkthumbnailPath2[bookmarkindex].path)}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pagination-controls">
                  <span onClick={handlebookmarkPrev2} style={{ cursor: 'pointer', color: bookcurrentPage2 === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                  <span> {bookcurrentPage2} / {Math.ceil(bookmarktotal2 / itemsPerPage)}</span>
                  <span onClick={handlebookmarkNext2} style={{ cursor: 'pointer', color: bookcurrentPage2 >= Math.ceil(bookmarktotal2 / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                </div>
          </Modal>
      </div>

        <Graph2 all1={all1} all2={all2} c={c} d={d} selectedGraph={selectedGraph} currentFrame1={Math.ceil(currentFrame1)} currentFrame2={Math.ceil(currentFrame2)} graphSize={graphSize} 
        activeStep1={activeStep1} activeStep2={activeStep2} videolength1 = {videolength1} videolength2={videolength2} isFlipped1 = {isFlipped1} isFlipped2 = {isFlipped2}
        selectedstep = {selectedstep} updateVisibleTables={updateVisibleTables} jsonlength1 = {jsonlength1} jsonlength2 = {jsonlength2}/>
      </div>
      <div className='Cplayback-buttons'>
          {/* <button onClick={() => handleChangePlaybackRate(0.5)}>0.5x</button>
          <button onClick={() => handleChangePlaybackRate(1.0)}>1.0x</button>
          <button onClick={() => handleChangePlaybackRate(2.0)}>2.0x</button>
          <button onClick={() => {setPlaying1(true); setPlaying2(true)}}>▶</button>
          <button onClick={() => {setPlaying1(false); setPlaying2(false)}}>∥</button> */}
          <button onClick={() => handleChangePlaybackRate(0.5)} style ={{border : playbackRate === 0.5 ? '4px solid red' : '3px solid white'}}>0.5x</button>
          <button onClick={() => handleChangePlaybackRate(1.0)} style ={{border : playbackRate === 1.0 ? '4px solid red' : '3px solid white'}}>1.0x</button>
          <button onClick={() => handleChangePlaybackRate(2.0)} style ={{border : playbackRate === 2.0 ? '4px solid red' : '3px solid white'}}>2.0x</button>
          <button onClick={() => pause()}>▶||</button>
          <button onClick={() => setRepeat(!repeat)} style ={{border : repeat === true ? '4px solid red' : '3px solid white'}}>repeat</button>
        </div>
      <div className='Cslider-container1'>
        <Slider
          min={0}
          max={(videolength1 - 1)}
          step={1} // 슬라이더 한 칸 범위, 이렇게 해야 90개 나옴 
          value={sliderValue1}
          onChange={handleSliderChange1}
          onChangeComplete={handleSliderChangeEnd1} // 슬라이더 변경 종료 핸들러
        />
      
       
        </div>
        <div className='Cslider-container2'>
        <Slider
          min={0} 
          // max={(frameRate2 - frameRate2/videolength2) + 0.2}
          // step={frameRate2/(videolength2 - 1)} // 슬라이더 한 칸 범위, 이렇게 해야 90개 나옴 
          max={(videolength2 - 1)}
          step={1} // 슬라이더 한 칸 범위, 이렇게 해야 90개 나옴 
          value={sliderValue2}
          onChange={handleSliderChange2}
          onChangeComplete={handleSliderChangeEnd2} // 슬라이더 변경 종료 핸들러
        />
        </div>

        <div className='Cslider-container3'>
          <Slider
            min={0}
            max={89}
            step={1} // 슬라이더 한 칸 범위, 이렇게 해야 90개 나옴 
            value={sliderValue3}
            onChange={handleSliderChange3}
            onAfterChange={handleSliderChangeEnd3} // 슬라이더 변경 종료 핸들러
          />
        </div>

      </div>
      <Canvas2 coordinates1={coordinates1} coordinates2={coordinates2}frameRate1={frameRate1} frameRate2={frameRate2} 
      onCurrentFrameChange1={setCurrentFrame1} onCurrentFrameChange2={setCurrentFrame2} selectedGraph={selectedGraph}/>
      </div>
    </>
  );
};

export default React.memo(CoachCompare);