import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Slider from 'rc-slider'; // 슬라이더 라이브러리
import 'rc-slider/assets/index.css'; // 슬라이더 스타일
import Modal from 'react-modal';
import Graph1 from '../Components/Graph1';
import Canvas1 from '../Components/Canvas1';
import AWS, { SimSpaceWeaver } from 'aws-sdk';
import '../Styles/solo.css';
import { useToken } from '../Components/TokenContext';
import { showAlert } from '../Components/alert';
import { useSocket } from "../Components/SocketContext"
import { debounce } from 'lodash';
import { isMobile, isTablet } from 'react-device-detect';

const CoachSolo = () => {

  const {socket} = useSocket();
  const navigate = useNavigate();
  const location = useLocation();

//----------------------------------------------------------------------------------------
                            // 마이페이지 일반영상 개인분석
  const Generalvideopath = location.state?.videopath; // 마이페이지에서 가져옴
  const Generalindex = location.state?.generalindex; // 마이페이지에서 가져옴
  const Generalpage = sessionStorage.getItem('generalpage') // 마이페이지 페이지
  const Generalcursor = sessionStorage.getItem('generalcursor') ? JSON.parse(sessionStorage.getItem('generalcursor')) : []; // 마이페이지 커서

  const [generalindex, setGeneralindex] = useState(Generalindex);
  const [generalvideopath, setGeneralvideopath] = useState(Generalvideopath);
  const [generalpage, setGeneralPage] = useState(Generalpage);
  const [generalcursor, setGeneralcursor] = useState(Generalcursor);
 
                          // 마이페이지 일반영상 개인분석
//----------------------------------------------------------------------------------------
                          // 마이페이지 북마크영상 개인분석

  const Mybookmarkvideopath = location.state?.videopath; // 마이페이지에서 가져옴
  const Mybookmarkindex = location.state?.bookmarkindex; // 마이페이지에서 가져옴
  const Bookmarkpage = sessionStorage.getItem('bookmarkpage') // 마이페이지 페이지
  const Bookmarkcursor = sessionStorage.getItem('bookmarkcursor') ? JSON.parse(sessionStorage.getItem('bookmarkcursor')) : []; // 마이페이지 커서

  const [mybookmarkindex, setMybookmarkindex] = useState(Mybookmarkindex);
  const [mybookmarkvideopath, setMybookmarkvideopath] = useState(Mybookmarkvideopath);
  const [mybookmarkpage, setMybookmarkPage] = useState(Bookmarkpage);
  const [mybookmarkcursor, setMybookmarkcursor] = useState(Bookmarkcursor);

                          // 마이페이지 북마크영상 개인분석
//----------------------------------------------------------------------------------------
                          // 회원관리 일반영상 개인분석

  const Studentvideopath = location.state?.videopath;
  const Studentindex = location.state?.studentindex;
  const Studentpage = sessionStorage.getItem('studentpage')
  const Studentcursor = sessionStorage.getItem('studentcursor') ? JSON.parse(sessionStorage.getItem('studentcursor')) : [];
  const Studentid = sessionStorage.getItem('studentId') // 학생 고유 id
  
  const [mystudentindex, setMystudentindex] = useState(Studentindex);
  const [mystudentvideopath, setMystudentvideopath] = useState(Studentvideopath);
  const [mystudentpage, setMystudentPage] = useState(Studentpage);
  const [mystudentcursor, setMystudentcursor] = useState(Studentcursor);
  const [mystudentId, setMystudentid] = useState(Studentid);

                            // 회원관리 일반영상 개인분석
//----------------------------------------------------------------------------------------

  //const [selectedGraph, setSelectedGraph] = useState([]);
  const [currentFrame, setCurrentFrame] = useState(0); // currentFrame 상태 추가
  const [videolength, setVideolength] = useState();
  const [jsonlength, setJsonlength] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [pathChecked, setPathChecked] = useState(true);
  const [c, setC] = useState([]); // 7step
  const [e, setE] = useState([]); // 썸네일 경로
  const [f, setF] = useState([]); // 썸네일 경로
  const [all, setAll] = useState([]); // 전체 데이터
  const [frameRate, setFrameRate ] = useState();
  const [videoUrl, setVideoUrl] = useState(null);
  const { studentId, coachId } = useToken();
  const [selectedVideoPath, setSelectedVideoPath] = useState(''); // 선택한 비디오 경로 추가
  const [videopath, setVideoPath] = useState([]);
  const [index, setIndex] = useState(null);
  const [selectedstep, setSelectedStep] = useState('');
  const [borderselectedstep, setBorderselectedStep] = useState('');
  const { setJsonAll, setMetaJson } = useToken();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [videoSize, setVideoSize] = useState({width: '0px', height: '0px'});
  const [graphSize, setGraphSize] = useState({width: 0, height: 0});
  const [showShoulders, setShowShoulders] = useState(false);
  const [showHips, setShowHips] = useState(false);
  const [showKnees, setShowKnees] = useState(true);
  const [showSpines, setShowSpines] = useState(true);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [thumbnails, setThumbnailUrls] = useState([]);
  const [thumbnailPath, setThumbnailPath] = useState([]);// 썸네일 전체 경로
  const [socketflag, setSocketflag] = useState(0);
  const [uploadFlag, setUploadFlag] = useState(false);

  const [mystudent, setMystudent] = useState([]); // 내 회원(대기 중 포함)
  const [selectedStudentId, setSelectedStudentId] = useState('self'); // 본인이면 self 아니면 학생Id
  const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
  const [selectedGraph, setSelectedGraph] = useState(['right_wrist.x']);
  const [allshow, setAllshow] = useState(false);
  const [allclear, setAllclear] = useState(false);

  const [bookmakrthumbnailPath, setBookmarkThumbnailPath] = useState([]);// 북마크 썸네일 전체 경로
  const [bookmarkthumbnails, setBookmarkThumbnailUrls] = useState([]); // 모달에 띄우는 북마크 썸네일 -> blob형식
  const [bookmarkvideopath, setBookmarkVideoPath] = useState([]);
  const [bookmarkindex, setBookmarkIndex] = useState(null);

  // const [publicthumbnailPath, setPublicThumbnailPath] = useState([]);// 북마크 썸네일 전체 경로
  // const [publicthumbnails, setPublicThumbnailUrls] = useState([]); // 모달에 띄우는 북마크 썸네일 -> blob형식
  // const [publicvideopath, setPublicVideoPath] = useState([]);
  // const [publicindex, setPublicIndex] = useState(null);

  const [cursor, setCursor] = useState([0]); // 모달 커서
  const [total, setTotal] = useState(-1); // 총 영상 개수
  const [videoId, setVideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [jsonarr, setJsonarr] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [currentPage, setCurrentPage] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [bookmarkcursor, setBookmarkcursor] = useState([0]); // 모달 커서
  const [bookmarktotal, setBookmarktotal] = useState(-1); // 총 영상 개수
  const [bookvideoId, setBookvideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [bookjsonarr, setBookjsonarr] = useState([]); // // 북마크영상 id 파싱을 위한 take만큼의 json 배열
  const [bookcurrentPage, setBookcurrentPage] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)

  const [studentcursor, setStudentcursor] = useState([0]); // 모달 커서
  const [studenttotal, setStudenttotal] = useState(-1); // 총 영상 개수
  const [studentvideoId, setStudentvideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용
  const [studentjsonarr, setStudentjsonarr] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [studentcurrentPage, setStudentCurrentPage] = useState(1); // 모달 현재 페이지 번호 (1부터 시작)


  var itemsPerPage = 6;
  if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
    itemsPerPage = 4;
  }

  Modal.setAppElement('#root')
  const steps = ['address', 'take_away', 'half', 'top', 'down_half', 'impact', 'follow_through', 'finish'];
  const { jsonall } = useToken();
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
        // 태블릿 가로 모드일 때의 크기 설정
        setVideoSize({ width: '270px', height: '360px' });
        setGraphSize({width: 500, height: 300})
      } else {
        // 기타 모드일 때의 크기 설정
        setVideoSize({ width: '540px', height: '720px' });
        setGraphSize({width: 800, height: 600})
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleGraphVisibility = () => {
    setShowAdvanced(!showAdvanced); // Toggle the state
};

const toggleDrawingMode = () => {
  setIsDrawingMode(!isDrawingMode);
};

  let joint = ["left_wrist", "right_wrist", "left_hip", "right_hip","left_shoulder", "right_shoulder", "left_knee", "right_knee",
  "left_heel", "right_heel", "left_toe", "right_toe","left_eye", "right_eye", "left_elbow", "right_elbow", "ears"];
  let joint2=[]

  joint.forEach(idx => {
    joint2.push(idx+'.x');
    joint2.push(idx+'.y');
  })

  joint = joint2;


const take = isTablet ? 4 : 6;
const token2 = 'Bearer ' + sessionStorage.getItem('token')
const apiId = sessionStorage.getItem('apiId');
const apitype = sessionStorage.getItem('apitype');
let i;

const thumbnailname = (path) => {
  const parts = path.split('/');
  const filename = parts[parts.length - 1];
  const dateTimePart = filename.split('.')[0];
  return dateTimePart;
};

if(socket){
  socket.on("KM:upload", data => {
    console.log("KM:upload")
    setUploadFlag(true)
    setSocketflag(socketflag+1);
  })
}

useEffect(() => {
  if(Generalindex !== undefined && Mybookmarkindex === undefined && index === null && bookmarkindex === null){

    setSelectedVideoPath(Generalvideopath);
    if (videoId !== null) {
      setVideoId(null);
    }
    if (bookvideoId !== null) {
      setBookvideoId(null);
    }
    if (index !== null) {
      setIndex(null);
    }
    if (bookmarkindex !== null) {
      setBookmarkIndex(null);
    }
  }
  else if(Mybookmarkindex !== undefined && Generalindex === undefined && index === null && bookmarkindex === null){

    setSelectedVideoPath(Mybookmarkvideopath);
    if (videoId !== null) {
      setVideoId(null);
    }
    if (bookvideoId !== null) {
      setBookvideoId(null);
    }
    if (index !== null) {
      setIndex(null);
    }
    if (bookmarkindex !== null) {
      setBookmarkIndex(null);
    }
  }
  else if(Studentindex !== undefined && Mybookmarkindex === undefined && Generalindex === undefined && index === null && bookmarkindex === null){

    setSelectedVideoPath(Studentvideopath);
    if (videoId !== null) {
      setVideoId(null);
    }
    if (bookvideoId !== null) {
      setBookvideoId(null);
    }
    if (index !== null) {
      setIndex(null);
    }
    if (bookmarkindex !== null) {
      setBookmarkIndex(null);
    }
  }
}, [Generalindex, Mybookmarkindex, Generalvideopath, Mybookmarkvideopath, index, bookmarkindex, selectedVideoPath, videoId, bookvideoId, bookmarkindex,socket]);

useEffect(() => { // 일반영상 dataJSON 받아오기

  if(selectedStudentId === 'self'){ // 본인 일 때
    const fetchData = async() => {
      let url;

      if(generalindex !== undefined && index === null && mybookmarkindex === undefined && bookmarkindex === null){
        url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('generalId')}/${apitype}/${apiId}`;
      }
      else if(mybookmarkindex === undefined && bookvideoId === null){
        url = `http://117.16.243.158:9000/myswing/${videoId}/${apitype}/${apiId}`;
      }
      try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();
      const myjson = JSON.parse(json.data.swingInfo.dataJSON);
      const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
    
      setCoordinates(data);
      setVideolength(myjson.data.video_length);
      setJsonlength(myjson.data.json_length);
      setFrameRate(adjustframeRate)
      setAll(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll(myjson);
      setC(myjson.data.golf_step);
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
    const fetchStudentData = async() => { // 회원 일반영상 dataJSON
      let url;

      if(mystudentindex !== undefined){
        url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('studentvideoId')}/${apitype}/${apiId}`;
      }
      else{
        url = `http://117.16.243.158:9000/myswing/${studentvideoId}/${apitype}/${apiId}`;
      }
      try {
      const response = await fetch(url,{
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
        if(response.status === 401)
        {
          //showAlert('로그인 후 이용해 주세요');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
          //navigate('/login');
        }
        const json = await response.json();
        const myjson = JSON.parse(json.data.swingInfo.dataJSON);
        const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

      let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;

      setCoordinates(data);
      setVideolength(myjson.data.video_length);
      setJsonlength(myjson.data.json_length);
      setFrameRate(adjustframeRate)
      setAll(myjson.data.frames.map((frame)=> frame.point));
      setJsonAll(myjson);
      setC(myjson.data.golf_step);
      const transformedData = transformSwingsData(json);
      setMetadata(transformedData);

      }catch(error){
        console.log('Error', error);
        setE([]);
      }
    };
    fetchStudentData();
  }
}, [selectedStudentId, index, videoId, currentPage, generalpage, bookmarkindex, mystudentindex, socket]);

useEffect(() => { // 일반영상 json 받아오기

  if(selectedStudentId === 'self'){
    const fetchData = async() => { // json가져오기
      let url;

      if (generalindex !== undefined && index === null && mybookmarkindex === undefined && bookmarkindex === null) {
        url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}?take=${take}&cursor=${cursor[currentPage - 1]}`; // generalcursor[generalPage - 1]
      } else {
        url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}?take=${take}&cursor=${cursor[currentPage - 1]}`;
      }
      try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        navigate('/login');
      }
      
      const json = await response.json();
      setJsonarr(json);
      const videopath = json.data.myswings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.myswings.map((swing) => ({ path: swing.thumbnail }));

      if ((generalindex === undefined && index === null && mybookmarkindex === undefined && bookmarkindex === null) || uploadFlag === true) {
        setSelectedVideoPath(videopath[0].path);
        setVideoId(json.data.myswings[0].id);
      }else{
        setVideoId(json.data.myswings[index === null ? 0 : index].id);
      }
  
      setE(thumbnailpath);
      setVideoPath(videopath)
      setThumbnailPath(thumbnailpath)
 
      cursor[currentPage] = json.data.cursor
      setCursor([...cursor]);

      if(total === -1){
        setTotal(json.data.total);
      }
  
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchData();
  }
  else{
  const fetchStudentData = async() => { // 회원 일반영상 json 가져오기

    let url;

    if (mystudentindex !== undefined) {
      url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}/student?studentId=${mystudentId}&take=${take}&cursor=${studentcursor[studentcurrentPage-1]}`; // mystudentcursor[mystudentpage - 1]
    } else {
      url = `http://117.16.243.158:9000/myswing/${apitype}/${apiId}/student?studentId=${selectedStudentId}&take=${take}&cursor=${studentcursor[studentcurrentPage-1]}`;
    }
    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
      if(response.status === 401)
      {
        //showAlert('로그인 후 이용해 주세요');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        //navigate('/login');
      }
      const json = await response.json();
      setStudentjsonarr(json);
      const videopath = json.data.studentSwings.map((swing) => ({ path: swing.videoPath }));
      const thumbnailpath = json.data.studentSwings.map((swing) => ({ path: swing.thumbnail }));

      // if ((mystudentindex !== undefined && generalindex === undefined && index === null && mybookmarkindex === undefined && bookmarkindex === null) || uploadFlag === true) {
      //   setSelectedVideoPath(videopath[0].path);
      //   setStudentvideoId(json.data.studentSwings[0].id);
      // }else{
      //   setStudentvideoId(json.data.studentSwings[index === null ? 0 : index].id);
      // }

    setVideoPath(videopath)
    setThumbnailPath(thumbnailpath)
    setF(thumbnailpath);

    const transformedData = transformSwingsData(json);
    setMetadata(transformedData);

    studentcursor[studentcurrentPage] = json.data.cursor
    setStudentcursor([...studentcursor]);

    if(studenttotal === -1){
      setStudenttotal(json.data.total);
    }

    }catch(error){
      console.log('Error', error);
      setE([]);
    }
  };
  fetchStudentData();
}

}, [selectedStudentId, selectedVideoPath, index, socketflag, currentPage, generalpage, generalindex, studentcurrentPage, mystudentindex, socket]);

// ---------------------------------------------------

useEffect(() => { // 북마크영상 dataJSON 받기

  const fetchBookmarkData = async() => {

    let url;
    
    if(mybookmarkindex !== undefined && generalindex === undefined && index === null && bookmarkindex === null){
      url = `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('bookmarkId')}/${apitype}/${apiId}`;
    }
    else if(bookmarkindex !== null && index === null){
      url = `http://117.16.243.158:9000/myswing/${bookvideoId}/${apitype}/${apiId}`;
    }
    try {
    const response = await fetch(url,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      //navigate('/login');
    }

    const json = await response.json();
    console.log(json)
    const myjson = JSON.parse(json.data.swingInfo.dataJSON);
    const data = myjson.data.frames.map((frame, i) => ({ frame: i, data: frame.point }));

    let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;

    //setBookmarkAll(myjson);
    setCoordinates(data);
    setVideolength(myjson.data.video_length);
    setJsonlength(myjson.data.json_length);
    setFrameRate(adjustframeRate)
    setAll(myjson.data.frames.map((frame)=> frame.point));
    setJsonAll(myjson);
    setC(myjson.data.golf_step);

    }catch(error){
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
},[selectedStudentId, index, bookmarkindex, bookvideoId ,bookcurrentPage, mybookmarkpage]);
// selectedStudentId, index, videoId, currentPage, generalpage, bookmarkindex 수정 전

useEffect(() => { // 북마크영상 json 받기
  const fetchBookmarkData = async() => {

    let url;

    if(mybookmarkindex !== undefined && generalindex === undefined && index === null && bookmarkindex === null){
      url = `http://117.16.243.158:9000/bookmark/${apitype}/${apiId}?take=${take}&cursor=${bookmarkcursor[bookcurrentPage - 1]}`; // mybookmarkcursor[mybookmarkpage - 1]
    } else{
      url = `http://117.16.243.158:9000/bookmark/${apitype}/${apiId}?take=${take}&cursor=${bookmarkcursor[bookcurrentPage - 1]}`;
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname');
      }

      const json = await response.json();
      setBookjsonarr(json);
      const thumbnailpath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.thumbnail }));
      const videopath = json.data.bookmarks.map((bookmark) => ({ path: bookmark.myswing.videoPath }));
      
      //setBookvideoId(json.data.bookmarks[bookmarkindex === null ? 0 : bookmarkindex])
      setBookmarkThumbnailPath(thumbnailpath);
      setBookmarkVideoPath(videopath);

      bookmarkcursor[bookcurrentPage] = json.data.cursor;
      setBookmarkcursor([...bookmarkcursor]);

      if(bookmarktotal === -1) {
        setBookmarktotal(json.data.total);
      }
    } catch(error) {
      console.log('Error', error);
    }
  };
  fetchBookmarkData();
}, [bookmarkindex, bookcurrentPage, mybookmarkpage, mybookmarkindex, bookvideoId]);
// bookmarkindex, bookcurrentPage, mybookmarkpage, mybookmarkindex 수정 전

// ---------------------------------------------------


// useEffect(() => { // 공개영상
//   const fetchData = async() => {

//     try {
//     const response = await fetch(`http://117.16.243.158:9000/myswing/open/${apitype}/${apiId}?take=${take}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': token2,
//         'Content-Type': 'application/json',
//       },
//     });
//     if(response.status === 401)
//     {
//       //showAlert('로그인 후 이용해 주세요');
//       sessionStorage.removeItem('token');
//       sessionStorage.removeItem('nickname')
//       //navigate('/login');
//     }
    
//     const json = await response.json();

//     var fetchjson = [];
//     var data = []; // 이거 가지고 원래 하던대로 parsing하면 됨
//     var videopath = [];
//     if(publicindex === null){
//       var myjson = JSON.parse(json.data.myswings[0].dataJSON);
//     }
//     else{
//       var myjson = JSON.parse(json.data.myswings[publicindex].dataJSON);
//     }

//     var metajson = json.data;
//     var thumbnailpath = [];
//     setMetaJson(metajson);

//     for(var i =0; i< json.data.myswings.length; i++)
//     {
//       var apidata = {order : i, data : json.data.myswings[i].dataJSON} 
//       var apivideopath = {path : json.data.myswings[i].videoPath}
//       var thumbnail = {path : json.data.myswings[i].thumbnail }
//       fetchjson.push(apidata) // 드롭박스에 있는 영상의 json
//       videopath.push(apivideopath) // 드롭박스 영상의 path
//       thumbnailpath.push(thumbnail)
//     }
//     //setVideoPath(videopath)

//     for (var i = 0; i < myjson.data.json_length; i++){ // 드롭다운에서 선택한 index로 myswings[index]까지
//       var all = { frame : i, data : myjson.data.frames[i].point } // 이것도
//       data.push(all)
//   }

//       let adjustframeRate = myjson.data.video_fps === 24 ? 20 : 20;
//       setPublicThumbnailPath(thumbnailpath)
//       setPublicVideoPath(videopath)
//       if(publicindex !== null){
//         setCoordinates(data);
//         setVideolength(myjson.data.video_length);
//         setJsonlength(myjson.data.json_length);
//         setFrameRate(adjustframeRate)
//         setAll(myjson.data.frames.map((frame)=> frame.point));
//         setJsonAll(myjson);
//         setC(myjson.data.golf_step);
//       }
//     }catch(error){
//       console.log('Error', error);
//     }
//   };
//   fetchData();

// }, [publicindex]);


useEffect(() => {// s3 영상 다운로드

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
    region: 'ap-northeast-2' // e.g., 'us-west-2'
  });
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';
  const objectKey = selectedVideoPath;

  const params = {
    Bucket: bucketName,
    Key: objectKey
  };

s3.getObject(params, (error, data) => {
    if (error) {
      console.error("Error downloading video from S3:", error);
    } else {
      // Assuming the data is a Blob
      const blob = new Blob([data.Body], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setVideoUrl(videoUrl);
    }
  });

},[selectedVideoPath, socketflag, cursor]);

useEffect(() => { // 일반영상 썸네일
  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2' 
  });

  // Initialize S3 client
  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = thumbnailPath.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path,
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setThumbnailUrls(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (thumbnailPath.length > 0) {
    fetchThumbnails();
  }
}, [thumbnailPath, socketflag, cursor]);

useEffect(() => { // 북마크 영상 썸네일
  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2' 
  });

  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = bookmakrthumbnailPath.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path,
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setBookmarkThumbnailUrls(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (bookmakrthumbnailPath.length > 0) {
    fetchThumbnails();
  }
}, [bookmakrthumbnailPath, bookmarkcursor]);

useEffect(() => {
  const fetchStudents = async() => { // 등록된 학생정보 가져오기 Approve로 파싱
    try {
    const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });
    if(response.status === 401)
    {
      //showAlert('로그인 후 이용해 주세요');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
    }
    const data = await response.json();
    console.log(data)
      if (data.data && data.data.lessons) {

        const studentData = data.data.lessons.map((user) => ({
            lessonId: user.id,
            studentid: user.student.id,
            name: user.student.user.name,
            loginId: user.student.user.loginId,
            isApprove: user.isApprove
        }));
        setMystudent(studentData);
  }
    }catch(error){
      console.log('Error', error);
    }
  };
  fetchStudents();

}, [coachId]);

const transformSwingsData = (generaldata) => {
  const transformedData = {};
  generaldata.data.studentSwings.forEach((swing) => {
    
    transformedData[swing.id] = {
      clubType: swing.clubType,
      createdAt: swing.createdAt,
      isOpen: swing.isOpen,
      note: swing.note,
    };
  });
  return transformedData;
};
console.log(total, studenttotal)

const handleAccountChange = (e) => {
  setSelectedStudentId(e.target.value);
  setIndex(null);
  setVideoId(null);
};

  const [playbackRate, setPlaybackRate] = useState(1.0); // 재생 속도 상태 추가
  const [playing, setPlaying] = useState(false); // 영상 재생 여부 상태 추가
  const playerRef = useRef(null); // ReactPlayer 참조를 위한 ref
  const [repeat, setRepeat] = useState(false);
  const [steprepeat, setSteprepeat] = useState(false);
  const [swingtype, setSwingtype] = useState('normal')

  // 슬라이더 값 상태 추가
  const [sliderValue, setSliderValue] = useState();

  const handleTimeUpdate = () => {
    if (!playerRef.current) return;

    const video = playerRef.current;
    const duration = video.duration;
    const currentTime = video.currentTime;
    const timePerFrame = 1 / frameRate;

    if (duration - currentTime <= 2*timePerFrame) {
        setPlaying(false);
    }
};
  // 슬라이더 값 변경 핸들러
  const handleSliderChange = (value) => {
    setSliderValue(value);
    setCurrentFrame(value);
    //debouncedSeek(value);
};


  // 슬라이더 변경이 끝난 후에 영상 제어
  const handleSliderChangeEnd = (sliderValue) => {
    if (playerRef.current) {
      let targetTime = sliderValue / frameRate;
      const roundedTime = Number((Math.round(targetTime * 100) / 100).toFixed(2)); // 소수점 두 자리로 반올림


      if (!isNaN(targetTime) && isFinite(targetTime)) {
        const maxTime = playerRef.current.getDuration(); // 영상의 총 시간
        let validTime // 원하는 재생시간
        if(sliderValue % 41 !== 0){
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2));
        }
        else{
          validTime = Number(Math.min(Math.max(targetTime, 0), maxTime).toFixed(2)) + 0.001;
        }

        // console.log(`Slider Value: ${sliderValue}`);
        // console.log(`Target Time: ${targetTime}`);
        // console.log(`Rounded Time: ${roundedTime}`);
        // console.log(`Valid Time: ${validTime}`);
        playerRef.current.seekTo(validTime, 'second');
        playerRef.current.getInternalPlayer().pause(); // 영상 재생 멈춤
      }
    }
  };

  const onSeek = (seconds) => {
    console.log(`Seeked to time: ${seconds}`);
  };

  // 재생 속도 변경 핸들러
  const handleChangePlaybackRate = (rate) => {
    setPlaybackRate(rate);
    if (playerRef.current && playerRef.current.getInternalPlayer()) {
      playerRef.current.getInternalPlayer().playbackRate = rate;
    }
  };

  const animationFrameId = useRef(null);  // 애니메이션 ID 저장
  const currentStepRef = useRef(null);  // 현재 반복 중인 스텝 저장

  const handleStepButtonClick = (step) => {
    setBorderselectedStep(step);
    if(step === "AD") step = 'address'
    else if(step === "TA") step = 'take_away'
    else if(step === "HF") step = 'half'
    else if(step === "TP") step = 'top'
    else if(step === "DH") step = 'down_half'
    else if(step === "IP") step = 'impact'
    else if(step === "FT") step = 'follow_through'
    else if(step === "FN") step = 'finish'

    // 🔥 기존 애니메이션 취소 (이전 루프 제거)
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
  }
    setSelectedStep(step);
    setRepeat(false);  // 전체반복 끄기
    currentStepRef.current = step;  // 현재 스텝 저장

    if (step === 'address') {
      if (playerRef.current) {
        playerRef.current.seekTo(0, 'seconds');
        setPlaying(false);
        setSliderValue(0);
      }
    }

    if (!jsonall.data.golf_step[step]) return;

    const currentIndex = steps.indexOf(step);
    const downHalfExists = 'down_half' in jsonall.data.golf_step;
    const startIndex = (() => {
      if (!downHalfExists && step === 'impact') {
          return currentIndex === 1 ? 0 : currentIndex - 2;
      }
      return currentIndex === 0 ? 0 : currentIndex - 1;
    })();
    const startFrame = step === 'address' ? 0 : c[steps[startIndex]];

    let targetFrame;
    if (step === 'finish') {
      if (playerRef.current) {
        const duration = playerRef.current.getDuration();
        targetFrame = (Math.floor(duration * frameRate)) - 1;
      } else {
        return;
      }
    } else {
      targetFrame = c[step];
    }
    
    const targetTime = targetFrame / frameRate;

    const moveToFrame = () => {
      if (playerRef.current && currentStepRef.current === step) {
        const currentTime = playerRef.current.getCurrentTime();

        if (currentTime < targetTime) {
          animationFrameId.current = requestAnimationFrame(moveToFrame);  // 🔄 새로운 애니메이션 실행
        } else {
          setPlaying(false);
          setSliderValue(targetFrame);

          if (steprepeat && currentStepRef.current === step) {
            // 🎯 steprepeat이 true면 무한 반복
            setTimeout(() => {
              if (currentStepRef.current === step && playerRef.current) {  // 🔥 선택한 스텝이 변경되지 않았는지 확인
                playerRef.current.seekTo(startFrame / frameRate, 'seconds'); // 다시 시작 프레임으로 이동
                setPlaying(true);
                animationFrameId.current = requestAnimationFrame(moveToFrame);
              }
            }, 500); // 0.5초 대기 후 다시 반복 시작
          }
        }
      }
    };

    if (playerRef.current) {
      playerRef.current.seekTo(startFrame / frameRate, 'seconds');
      setPlaying(true);
      animationFrameId.current = requestAnimationFrame(moveToFrame);  // ✅ 새로운 애니메이션 실행
    }
  };
  
  const handleAllClearToggle = () => {
    setSelectedGraph([]);
  };

  const handleAllSetToggle = () => {
    setSelectedGraph(joint);
  };

  const handleGeneralclearToggle = () =>{
    setAllclear(true);
    setAllshow(false);
    setSelectedGraph([]);
    setShowShoulders(false);
    setShowHips(false);
    setShowKnees(false);
    setShowSpines(false);
  }

  const handleGeneralSetToggle = () => {
    setPathChecked(true);
    setAllshow(true);
    setAllclear(false);
    setSelectedGraph(['right_wrist.x', 'ears.x']);
    setShowShoulders(true);
    setShowHips(true);
    setShowKnees(true);
    setShowSpines(true);
  };

  const handleGraphSelection = (graphName) => {
    setAllclear(false);
    setAllshow(false);
    if (selectedGraph.includes(graphName)) {
      setSelectedGraph(selectedGraph.filter(item => item !== graphName));
      if (!showAdvanced && selectedGraph.length === 1) {
        setPathChecked(false);
      }
    } else {
      setSelectedGraph([...selectedGraph, graphName]);
      // 일반 모드에서는 체크박스를 선택할 때 경로를 보여줌
      if (!showAdvanced) {
        setPathChecked(true);
      }
    }
  };

  const handlePathCheckboxChange = () => {
    setPathChecked(!pathChecked); // pathChecked 값을 반전시켜 업데이트
  };

  // const handleVideoSelect = (event) => {
  //   const selectedPath = event.target.value;
  //   const index = videopath.findIndex((video) => video.path === selectedPath);
  //   setIndex(index);
  //   setSelectedVideoPath(selectedPath);
  // };

  const handleVideoEnded = () => {
    setPlaying(false);
    setSliderValue(videolength); // 원래 0 이였음
    setCurrentFrame(videolength); // 원래 0 이였음
  };

  const handleCheckboxChange = (checkboxName) => {
    setAllclear(false);
    if(allshow){
      setAllshow(false);
    }
    if (checkboxName === 'shoulders') {
      setShowShoulders(!showShoulders);
    } else if (checkboxName === 'hips') {
      setShowHips(!showHips);
    }
    else if (checkboxName === 'knees') {
      setShowKnees(!showKnees);
    }
    else if (checkboxName === 'spines') {
      setShowSpines(!showSpines);
    }
  };

  const pause = () => {
    setPlaying(!playing);
  }

  // useEffect(() => { // 공개 영상 썸네일
  //   AWS.config.update({
  //     accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
  //     secretAccessKey: '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',
  //     region: 'ap-northeast-2' 
  //   });
  
  //   // Initialize S3 client
  //   const s3 = new AWS.S3();
  //   const bucketName = 'dmz-metadata';
  
  //   const fetchThumbnails = async () => {
  //     try {
  //       const thumbnailPromises = publicthumbnailPath.map((item) => {
  //         const params = {
  //           Bucket: bucketName,
  //           Key: item.path,
  //         };
  //         return s3.getObject(params).promise().then(data => {
  //           return URL.createObjectURL(new Blob([data.Body], { type: 'video/mp4' }));
  //         });
  //       });
  
  //       const thumbnails = await Promise.all(thumbnailPromises);
  //       setPublicThumbnailUrls(thumbnails);
  //     } catch (error) {
  //       console.error('Error fetching thumbnails:', error);
  //     }
  //   };
  
  //   if (publicthumbnailPath.length > 0) {
  //     fetchThumbnails();
  //   }
  // }, [publicthumbnailPath]);

  const handleThumbnailClick = (thumbnailUrl) => {
    
    const videoPath = thumbnailUrl.replace(".jpg", ".mp4");
    const index = videopath.findIndex((video) => video.path === videoPath);

    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setMystudentindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');
    setMystudentvideopath('');

    setIndex(index);
    setVideoUrl(videoPath)
    setSelectedVideoPath(videoPath);
    setBookmarkIndex(null);
    setBookvideoId(null);
    if(selectedStudentId !== 'self'){
      setStudentvideoId(studentjsonarr.data.studentSwings[index].id)
    }
};

  const handleBookmarkThumbnailClick = (thumbnailUrl) => {
    const videoPath = thumbnailUrl.replace(".jpg", ".mp4");
    const index = bookmarkvideopath.findIndex((video) => video.path === videoPath);

    setGeneralindex(undefined);
    setMybookmarkindex(undefined);
    setMystudentindex(undefined);
    setGeneralvideopath('');
    setMybookmarkvideopath('');
    setMystudentvideopath('');

    setBookmarkIndex(index);
    setVideoUrl(videoPath);
    setSelectedVideoPath(videoPath);

    setIndex(null);
    setVideoId(null);
    // if(selectedStudentId === 'self'){
    //   //setStudentvideoId(null)
    //   setBookvideoId(bookjsonarr.data.bookmarks[index].myswing.id);
    // }
    setBookvideoId(bookjsonarr.data.bookmarks[index].myswing.id);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePrev = () => {
    if(currentPage > 1){ setCurrentPage(currentPage - 1); // 0 미만으로 가지 않도록
  }
  };

  const handleNext = () => {
    if(currentPage < total/take){
      setCurrentPage(currentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const handlebookmarkPrev = () => {
    if(bookcurrentPage > 1){ setBookcurrentPage(bookcurrentPage - 1); // 0 미만으로 가지 않도록
  }
  };

  const handlebookmarkNext = () => {
    if(bookcurrentPage < bookmarktotal/take){
      setBookcurrentPage(bookcurrentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const handlestudentPrev = () => {
    if(studentcurrentPage > 1){ setStudentCurrentPage(studentcurrentPage - 1); // 0 미만으로 가지 않도록
  }
  };

  const handlestudentNext = () => {
    if(studentcurrentPage < studenttotal/take){
      setStudentCurrentPage(studentcurrentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  const stepTooltips = {
    AD: 'Address',
    TA: 'Take Away',
    HF: 'Half',
    TP: 'Top',
    DH: 'Down Half',
    IP: 'Impact',
    FT: 'Follow Through',
    FN: 'Finish'
  };

  const step = (jsonall && jsonall.data && jsonall.data.golf_step && jsonall.data.golf_step['down_half']) ? ['AD', 'TA', 'HF', 'TP', 'DH', 'IP', 'FT', 'FN'] : ['AD', 'TA', 'HF', 'TP', 'IP', 'FT', 'FN'];

  return (
    <>
      {/*---------------영상, 슬라이더, 배속, 재생, 일시정지---------------*/}
      <>
        <div className="video-name">
          {selectedVideoPath}
        </div>
        <div className='video-player'>
            <ReactPlayer
                ref={playerRef}
                className='react-player'
                url={videoUrl}
                width= {videoSize.width}
                height= {videoSize.height}
                playing={playing}
                muted={false}
                controls={false}
                light={false}
                pip={true}
                loop={repeat === true ? true : false}
                poster={videoUrl}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleVideoEnded}
                zIndex={-1}
                onSeek={onSeek}
            />
            {/* <div className='video-select'>
              <label htmlFor='video-dropdown'>Select a Video:</label>
                <select id='video-dropdown' value={selectedVideoPath} onChange={handleVideoSelect}>
                  {videopath.map((video, index) => (
                    <option key={index} value={video.path}>
                      {video.path}
                    </option>
                  ))}
                </select>
            </div> */}
            <div className='slider-container'>
                <Slider
                  min={0}
                  max={(videolength-1)}
                  step={1}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  onChangeComplete={handleSliderChangeEnd} // 슬라이더 변경 종료 핸들러
                />
            </div>
            <div className='playback-buttons'>
              {/* <button onClick={() => handleChangePlaybackRate(0.5)}>0.5x</button>
              <button onClick={() => handleChangePlaybackRate(1.0)}>1.0x</button>
              <button onClick={() => handleChangePlaybackRate(2.0)}>2.0x</button>
              <button onClick={() => setPlaying(true)}>▶</button>
              <button onClick={() => setPlaying(false)}>∥</button> */}
              <button onClick={() => handleChangePlaybackRate(0.5)} style ={{border : playbackRate === 0.5 ? '4px solid red' : '3px solid white'}}>0.5x</button>
              <button onClick={() => handleChangePlaybackRate(1.0)} style ={{border : playbackRate === 1.0 ? '4px solid red' : '3px solid white'}}>1.0x</button>
              <button onClick={() => handleChangePlaybackRate(2.0)} style ={{border : playbackRate === 2.0 ? '4px solid red' : '3px solid white'}}>2.0x</button>
              <button onClick={() => pause()}>▶||</button>
              <button onClick={() => setRepeat(!repeat)} style ={{border : repeat === true ? '4px solid red' : '3px solid white'}}>repeat</button>
            </div>
        </div>
      </>
      {/* ---------------영상, 슬라이더, 배속, 재생, 일시정지---------------*/}


      {/* ---------------스텝버튼, detail & general 버튼---------------*/}
      <>
        <div className='steps-buttons'>
              {step.map((step) => (
              <button
                key={step}
                className="step-button"
                onClick={() => handleStepButtonClick(step)}
                title={stepTooltips[step]}
                style={{
                  border: borderselectedstep === step ? '5px solid red' : '2px solid white', // 선택된 단계에 따라 테두리 스타일 변경
                }}
              >
                {step.toUpperCase()}
              </button>
        ))}
          <div className="option-button-container">
              <button style ={{backgroundColor: 'red'}} onClick={toggleGraphVisibility}>
                  {showAdvanced ? 'General' : 'Details'}
              </button>

          </div>
          <div className='draw'>
            <button onClick={toggleDrawingMode}>{isDrawingMode ? '지우기' : '그리기'}</button>
          </div>
       </div>
      </>
      {/* ---------------스텝버튼, detail & general 버튼---------------*/}

      <div className='choose-buttons'>
        <button onClick={() => setIsModalOpen(true)}>영상 선택</button>
        <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                style={{
                  overlay: {
                    zIndex: 5, // Set a high value for zIndex here
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '80%',
                    }
                }}
            >
              <select style={{fontSize : '2rem'}} value={selectedStudentId} onChange={handleAccountChange}>
                <option value="self">내 영상</option>
                {mystudent.filter(student => student.isApprove).map(student => (
                  <option key={student.studentid} value={student.studentid}>{student.name}</option>
                ))}
              </select>
              <button onClick={() => setIsModalOpen(false)}>닫기</button>
                {selectedStudentId === 'self' ?  
                  <label> 일반 영상 {total}개</label>
                  : <label> 일반 영상 {studenttotal}개</label>
                }
                  {selectedStudentId === 'self' ?
                  <div className="thumbnail-container">
                    {e && e.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick(e[index].path); setIsModalOpen(false);}}
                          src={thumbnails[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(e[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <span onClick={handlePrev} style={{ cursor: 'pointer', color: currentPage === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {currentPage} / {Math.ceil(total / itemsPerPage)}</span>
                      <span onClick={handleNext} style={{ cursor: 'pointer', color: currentPage >= Math.ceil(total / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  :
                  <div className="thumbnail-container">
                    {f && f.map((item, index) => (
                      <div className="thumbnail-item" key={index}>
                        <img 
                          onClick={() => {handleThumbnailClick(f[index].path); setIsModalOpen(false);}}
                          src={thumbnails[index]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(f[index].path)}
                        </div>
                      </div>
                    ))}
                    <div className="pagination-controls">
                      <span onClick={handlestudentPrev} style={{ cursor: 'pointer', color: studentcurrentPage === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                      <span> {studentcurrentPage} / {Math.ceil(studenttotal / itemsPerPage)}</span>
                      <span onClick={handlestudentNext} style={{ cursor: 'pointer', color: studentcurrentPage >= Math.ceil(studenttotal / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                    </div>
                  </div>
                  }
                  <label> 북마크 영상 {bookmarktotal}개</label>
                  <div className="thumbnail-container">
                    {bookmakrthumbnailPath && bookmakrthumbnailPath.map((item, bookmarkindex) => (
                      <div className="thumbnail-item" key={bookmarkindex}>
                        <img 
                          onClick={() => {handleBookmarkThumbnailClick(bookmakrthumbnailPath[bookmarkindex].path); setIsModalOpen(false);}}
                          src={bookmarkthumbnails[bookmarkindex]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(bookmakrthumbnailPath[bookmarkindex].path)}
                        </div>
                      </div>
                    ))}
                </div>
                <div className="pagination-controls">
                  <span onClick={handlebookmarkPrev} style={{ cursor: 'pointer', color: bookcurrentPage === 1 ? 'grey' : 'black' }}>&lt; 이전</span>
                  <span> {bookcurrentPage} / {Math.ceil(bookmarktotal / itemsPerPage)}</span>
                  <span onClick={handlebookmarkNext} style={{ cursor: 'pointer', color: bookcurrentPage >= Math.ceil(bookmarktotal / itemsPerPage) ? 'grey' : 'black' }}>다음 &gt;</span>
                </div>

                {/* <label> 공개 영상 </label>
                  <div className="thumbnail-container">
                    {publicthumbnailPath && publicthumbnailPath.map((item, publicindex) => (
                      <div className="thumbnail-item" key={publicindex}>
                        <img 
                          onClick={() => {handlePublicThumbnailClick(publicthumbnailPath[publicindex].path); setIsModalOpen(false);}}
                          src={publicthumbnails[publicindex]} 
                          alt={''} 
                          style={{ width: '200px', height: '400px' }}
                        />
                        <div className="thumbnail-title">
                          {thumbnailname(publicthumbnailPath[publicindex].path)}
                        </div>
                      </div>
                    ))}
                </div> */}
          </Modal>
      </div> 
      {/* ---------------테이블 체크박스(rR, rE, S,H,K,SP)---------------*/}

      <>
      <div className='table-checkbox'>
        {!showAdvanced && (
          <>
            <div className='checkbox-label-pairR'>
              <label className="switch">
                <input type="checkbox" checked={selectedGraph.includes('right_wrist.x')} onChange={() => {handleGraphSelection('right_wrist.x');}}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>손목경로</div>
            </div>
            
            <div className='checkbox-label-pairE'>
              <label className="switch">
                <input type="checkbox" checked={selectedGraph.includes('ears.x')} onChange={() => {handleGraphSelection('ears.x');}}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>머리경로</div>
            </div>
            
            <div className='checkbox-label-pairS'>
              <label className="switch">
                <input type="checkbox" checked={showShoulders} onChange={() => handleCheckboxChange('shoulders')}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>어깨</div>
            </div>
            
            <div className='checkbox-label-pairH'>
              <label className="switch">
                <input type="checkbox" checked={showHips} onChange={() => handleCheckboxChange('hips')}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>골반</div>
            </div>
            
            <div className='checkbox-label-pairK'>
              <label className="switch">
                <input type="checkbox" checked={showKnees} onChange={() => handleCheckboxChange('knees')}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>무릎</div>
            </div>
            
            <div className='checkbox-label-pairSP'>
              <label className="switch">
                <input type="checkbox" checked={showSpines} onChange={() => handleCheckboxChange('spines')}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>척추각</div>
            </div>
            
            <div className='checkbox-label-pairSP'>
              <label className="switch">
                <input type="checkbox" checked={selectedGraph.includes('right_knee.x')} onChange={() => {handleGraphSelection('right_knee.x');}}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>우측무릎</div>
            </div>
            
            <div className='checkbox-label-pairSP'>
              <label className="switch">
                <input type="checkbox" checked={allclear} onChange={() => handleGeneralclearToggle()}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>CLEAR</div>
            </div>
            
            <div className='checkbox-label-pairSP'>
              <label className="switch">
                <input type="checkbox" checked={allshow} onChange={() => handleGeneralSetToggle()}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>SET</div>
            </div>
            <br></br>
            <div className='checkbox-label-pairSP'>
              <label className="switch">
                <input type="checkbox" checked = {steprepeat} onChange={() => setSteprepeat(!steprepeat)}/>
                <span className="slider"></span>
              </label>
              <div className='general-label'>REPEAT</div>
            </div>
          </>
        )}
      </div>
    </>
    <div className = 'graph-container'>
      <div className='graph-checkbox'>
      {showAdvanced && ( 
        <>
          {/* <button onClick={() => setSwingtype('back')} style ={{border : swingtype === 'back' ? '4px solid red' : '3px solid white', marginLeft:350}}>Back_Swing</button>
          <button onClick={() => setSwingtype('down')} style ={{border : swingtype === 'down' ? '4px solid red' : '3px solid white', marginLeft:100}}>Down_Swing</button>
          <button onClick={() => setSwingtype('normal')} style ={{border : swingtype === 'normal' ? '4px solid red' : '3px solid white', marginLeft:100}}>Normal</button> */}
            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_wrist.x')} onChange={() => handleGraphSelection('left_wrist.x')}/>
                <div className='label'> left_wrist.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_wrist.y')} onChange={() => handleGraphSelection('left_wrist.y')}/>
                <div className='label'> left_wrist.y </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_wrist.x')} onChange={() => handleGraphSelection('right_wrist.x')}/>
                <div className='label'> right_wrist.x </div>
              </div>
            
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_wrist.y')} onChange={() => handleGraphSelection('right_wrist.y')}/>
                <div className='label'> right_wrist.y </div>
              </div>
            </div>



            <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_hip.x')} onChange={() => handleGraphSelection('left_hip.x')}/>
                  <div className='label'> left_hip.x </div>
                </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_hip.y')} onChange={() => handleGraphSelection('left_hip.y')}/>
                  <div className='label'> left_hip.y </div>
              </div>
              
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_hip.x')} onChange={() => handleGraphSelection('right_hip.x')}/>
                <div className='label'> right_hip.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_hip.y')} onChange={() => handleGraphSelection('right_hip.y')}/>
                  <div className='label'> right_hip.y </div>
              </div>
            </div>



            <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_shoulder.x')} onChange={() => handleGraphSelection('left_shoulder.x')}/>
                  <div className='label'> left_shoulder.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_shoulder.y')} onChange={() => handleGraphSelection('left_shoulder.y')}/>
                  <div className='label'> left_shoulder.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_shoulder.x')} onChange={() => handleGraphSelection('right_shoulder.x')}/>
                  <div className='label'> right_shoulder.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_shoulder.y')} onChange={() => handleGraphSelection('right_shoulder.y')}/>
                  <div className='label'> right_shoulder.y </div>
              </div>
            </div>


            <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_knee.x')} onChange={() => handleGraphSelection('left_knee.x')}/>
                  <div className='label'> left_knee.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_knee.y')} onChange={() => handleGraphSelection('left_knee.y')}/>
                  <div className='label'> left_knee.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_knee.x')} onChange={() => handleGraphSelection('right_knee.x')}/>
                  <div className='label'> right_knee.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_knee.y')} onChange={() => handleGraphSelection('right_knee.y')}/>
                  <div className='label'> right_knee.y </div>
              </div>
            </div> */}

            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_heel.x')} onChange={() => handleGraphSelection('left_heel.x')}/>
                  <div className='label'> left_heel.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_heel.y')} onChange={() => handleGraphSelection('left_heel.y')}/>
                  <div className='label'> left_heel.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_heel.x')} onChange={() => handleGraphSelection('right_heel.x')}/>
                  <div className='label'> right_heel.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_heel.y')} onChange={() => handleGraphSelection('right_heel.y')}/>
                  <div className='label'> right_heel.y </div>
              </div>
            </div> */}


          <div className='graph-checkbox2'>
            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_toe.x')} onChange={() => handleGraphSelection('left_toe.x')}/>
                  <div className='label'> left_toe.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_toe.y')} onChange={() => handleGraphSelection('left_toe.y')}/>
                  <div className='label'> left_toe.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_toe.x')} onChange={() => handleGraphSelection('right_toe.x')}/>
                  <div className='label'> right_toe.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_toe.y')} onChange={() => handleGraphSelection('right_toe.y')}/>
                  <div className='label'> right_toe.y </div>
              </div>
            </div> */}


            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_eye.x')} onChange={() => handleGraphSelection('left_eye.x')}/>
                  <div className='label'> left_eye.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_eye.y')} onChange={() => handleGraphSelection('left_eye.y')}/>
                  <div className='label'> left_eye.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_eye.x')} onChange={() => handleGraphSelection('right_eye.x')}/>
                  <div className='label'> right_eye.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_eye.y')} onChange={() => handleGraphSelection('right_eye.y')}/>
                  <div className='label'> right_eye.y </div>
              </div>
            </div> */}

            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_elbow.x')} onChange={() => handleGraphSelection('left_elbow.x')}/>
                  <div className='label'> left_elbow.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('left_elbow.y')} onChange={() => handleGraphSelection('left_elbow.y')}/>
                  <div className='label'> left_elbow.y </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_elbow.x')} onChange={() => handleGraphSelection('right_elbow.x')}/>
                  <div className='label'> right_elbow.x </div>
              </div>
                
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('right_elbow.y')} onChange={() => handleGraphSelection('right_elbow.y')}/>
                  <div className='label'> right_elbow.y </div>
              </div>
            </div> */}

          {/* <div>
            <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('ears.x')} onChange={() => handleGraphSelection('ears.x')}/>
                  <div className='label'> ears.x </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={selectedGraph.includes('ears.y')} onChange={() => handleGraphSelection('ears.y')}/>
                  <div className='label'> ears.y </div>
              </div>
            </div> */}

            {/* <div>
              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={(selectedGraph.length === 0) } onChange={() => handleAllClearToggle()} />
                <div className='label'> Clear </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked = {(selectedGraph.length === joint.length) } onChange={() => handleAllSetToggle()} />
                <div className='label'> Set </div>
              </div>

              <div className='checkbox-label-pair'>
                <input type='checkbox' checked={pathChecked} onChange={handlePathCheckboxChange}/>
                <div className='label'> path </div>
              </div>
            </div> */}
          </div>
        </>)}
      </div>
          <Graph1 all={all} c={c} selectedGraph={selectedGraph} currentFrame={currentFrame} graphSize={graphSize}
          frameRate={frameRate} videolength = {videolength} jsonlength = {jsonlength} selectedstep = {selectedstep} showAdvanced={showAdvanced}/>
    </div>
      <Canvas1 coordinates={coordinates} frameRate={frameRate} videolength = {videolength} onCurrentFrameChange={setCurrentFrame} currentFrame={currentFrame} 
      selectedGraph={selectedGraph} pathChecked={pathChecked} showShoulders={showShoulders} showHips={showHips} showKnees={showKnees} showSpines={showSpines} videoSize={videoSize}
      isDrawingMode={isDrawingMode} selectedstep = {selectedstep} jsonlength = {jsonlength}/>
    </>
    
  );
};

export default React.memo(CoachSolo);